import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    50: '#e9eaf0',
    100: '#c2c3d4',
    200: '#aaacc2',
    300: '#9194b1',
    400: '#797ca0',
    500: '#60648f',
    600: '#484c7d',
    700: '#30356c',
    800: '#171d5b',
    900: '#0b1152',
  },
  danger: {
    50: '#fce0e8',
    100: '#faccd9',
    200: '#f7b8ca',
    300: '#f5a3bb',
    400: '#f38fac',
    500: '#f17a9d',
    600: '#ee668e',
    700: '#ec527f',
    800: '#ea3d70',
    900: '#e93368',
  },
  warning: {
    50: '#fef2de',
    100: '#feeac7',
    200: '#fee2b1',
    300: '#fdd99b',
    400: '#fdd184',
    500: '#fcc86e',
    600: '#fcc058',
    700: '#fcb841',
    800: '#fbaf2b',
    900: '#fbab20',
  },
  success: {
    50: '#d9fff6',
    100: '#bffff0',
    200: '#a6ffeb',
    300: '#8cffe5',
    400: '#73ffdf',
    500: '#59ffd9',
    600: '#40ffd4',
    700: '#26ffce',
    800: '#0dffc8',
    900: '#00ffc5',
  },
};

const fonts = {
  heading: "'Inter', sans-serif",
  body: "'Inter', sans-serif",
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: 'medium',
      borderRadius: 'md',
    },
    variants: {
      solid: {
        bg: 'primary.800',
        color: 'white',
        _hover: {
          bg: 'primary.700',
        },
      },
      outline: {
        borderColor: 'primary.800',
        color: 'primary.800',
        _hover: {
          bg: 'primary.50',
        },
      },
    },
  },
  Card: {
    baseStyle: {
      container: {
        borderRadius: 'lg',
        boxShadow: 'md',
      },
    },
  },
  Heading: {
    baseStyle: {
      color: 'primary.800',
    },
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components,
  styles: {
    global: {
      body: {
        bg: 'white',
        color: 'gray.800',
      },
    },
  },
});

export default theme;