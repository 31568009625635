import React from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  Tooltip,
  Flex,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import SocialShareModal from "../ReusableComponents/SocialShareModal";
import { IconCopy, IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function ShareBox({ link }) {
  const { hasCopied, onCopy } = useClipboard(link);
  const toast = useToast();
  const { t } = useTranslation();

  const handleCopy = () => {
    onCopy();
    toast({
      description: t("dashboard.share.copy.success"),
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box
      bg="white"
      shadow="lg"
      borderRadius="xl"
      p={6}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Heading
        as="h3"
        size="md"
        color="primary.800"
        mb={2}
      >
        {t("dashboard.share.title")}
      </Heading>
      
      <Text
        color="gray.600"
        fontSize="sm"
      >
        {t("dashboard.share.description")}
      </Text>

      <Flex
        minH="50px"
        bg="gray.50"
        gap={4}
        borderRadius="xl"
        justify="center"
        align="center"
        my={5}
        p={4}
      >
        <Text color="gray.600" isTruncated maxW="70%">
          {link}
        </Text>
        <Tooltip
          label={t(hasCopied ? "dashboard.share.copy.tooltipCopied" : "dashboard.share.copy.tooltip")}
          hasArrow
          placement="right"
        >
          <IconButton
            aria-label={t("dashboard.share.copy.ariaLabel")}
            icon={hasCopied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
            size="sm"
            variant="ghost"
            colorScheme={hasCopied ? "green" : "gray"}
            onClick={handleCopy}
          />
        </Tooltip>
      </Flex>

      <SocialShareModal />
    </Box>
  );
}
