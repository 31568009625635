import "./Styles/App.css";
import LoginForm from "./Components/Auth/LoginForm";
import RegisterForm from "./Components/Auth/RegisterForm";
import WelcomePage from "./Components/Pages/WelcomePage"
import MainDashboard from "./Components/Pages/MainDashboard"
import Billing from "./Components/Pages/Billing";
import Bookings from "./Components/Pages/Bookings";
import Account from "./Components/Pages/Account";
import { UserProvider } from "./AuthProvider/UserContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useUser } from "./AuthProvider/UserContext";
import { ChakraProvider, Container } from "@chakra-ui/react";
import theme from './Theme';
import SeoHelmet from "./Components/Seo/SeoHelmet"
import IubendaScript from "./Components/ReusableComponents/Iubenda";
import { TermsAndConditionsScreen } from "./Components/Pages/TermsAndConditions";
import LockerOnboarding from "./Components/Pages/LockerOnboarding";
import SilverProgramPage from "./Components/Pages/Marketing/SilverProgramPage";
import GoldProgramPage from "./Components/Pages/Marketing/GoldProgramPage";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <UserProvider>
          <Content />
        </UserProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function Content() {
  const { isAuthenticated } = useUser();

  return (
    <Container maxW="100%" p={0}>
      <IubendaScript />
      <SeoHelmet/>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/locker-onboarding" element={<LockerOnboarding />} />
        <Route path="/mkt/silver-program" element={<SilverProgramPage />} />
        <Route path="/mkt/gold-program" element={<GoldProgramPage />} />
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <LoginForm />
          }
        />

        <Route
          path="/signup"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterForm />
          }
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <MainDashboard /> : <Navigate to="/" />}
        />

        <Route
          path="/bookings"
          element={isAuthenticated ? <Bookings /> : <Navigate to="/login" />}
        />

        <Route
          path="/billing"
          element={isAuthenticated ? <Billing /> : <Navigate to="/login" />}
        />
        <Route
          path="/account"
          element={isAuthenticated ? <Account /> : <Navigate to="/login" />}
        />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsScreen/>}
        />
      </Routes>
    </Container>
  );
}

export default App;