import React from "react";
import {
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Button,
  Select,
  VStack,
  Container,
  Heading,
  Text,
  Icon,
  useToast,
  FormErrorMessage,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { CheckIcon } from "@chakra-ui/icons";
import ClosboxV2 from "../../Api/ClosboxV2";

export default function ContactForm() {
  const { t, i18n } = useTranslation();
  const toast = useToast();

  // Stato per il caricamento e submit completato
  const [isLoading, setIsLoading] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  // Hook di react-hook-form con valori di default e regole di validazione
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      programType: "",
      subject: "",
      message: "",
      privacy: false,
    },
  });

  const closboxInstance = ClosboxV2.getInstance();

  /**
   * Funzione che gestisce l'invio del form.
   * React Hook Form fornisce handleSubmit che,
   * in caso di validazione superata, chiama onSubmit().
   */
  const onSubmit = async (values) => {
    setIsLoading(true);
    const currentLocale = i18n.language;

    try {
      // Aggiungiamo al messaggio il tipo di programma scelto:
      const modifiedMessage = `Programma di affiliazione richiesto: ${values.programType}\n\n${values.message}`;

      // Esempio di chiamata al tuo endpoint per creare un ticket
      await closboxInstance.createTicket(
        values.name,
        values.surname,
        values.email,
        values.phone,
        null,
        20, // esempio di "department" o "serviceId" che usi
        values.subject,
        modifiedMessage,
        currentLocale
      );

      // Se va tutto bene, mostriamo uno stato di "form inviato" e la success-page
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error creating ticket:", error);
      toast({
        title: "Error",
        description:
          "There was an error submitting your request. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Se il form è stato inviato con successo, mostriamo una schermata di conferma.
   */
  if (formSubmitted) {
    return (
      <Container maxW="md" py={8}>
        <VStack spacing={6} align="center">
          <Icon as={CheckIcon} w={12} h={12} color="green.500" />
          <Heading as="h2" size="lg" color="primary.800" textAlign="center">
            {t("contactForm.success.title")}
          </Heading>
          <Text color="primary.800" textAlign="center">
            {t("contactForm.success.subtitle")}
          </Text>
        </VStack>
      </Container>
    );
  }

  /**
   * Se il form NON è ancora inviato, mostriamo i campi e gestiamo validazione ed errori
   * tramite i "register" di react-hook-form.
   */
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }} gap={6}>
        {/* Name */}
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>{t("contactForm.labels.name")}</FormLabel>
            <Input
              {...register("name", { required: true })}
              placeholder={t("contactForm.placeholders.name")}
            />
            {errors.name && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Surname */}
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.surname}>
            <FormLabel>{t("contactForm.labels.surname")}</FormLabel>
            <Input
              {...register("surname", { required: true })}
              placeholder={t("contactForm.placeholders.surname")}
            />
            {errors.surname && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Email */}
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.email}>
            <FormLabel>{t("contactForm.labels.email")}</FormLabel>
            <Input
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("contactForm.validationErrors.invalidEmail"),
                },
              })}
              placeholder={t("contactForm.placeholders.email")}
            />
            {errors.email && (
              <FormErrorMessage>
                {errors.email.message ||
                  t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Phone */}
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.phone}>
            <FormLabel>{t("contactForm.labels.phone")}</FormLabel>
            <Input
              {...register("phone", { required: true })}
              placeholder={t("contactForm.placeholders.phone")}
            />
            {errors.phone && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Program Type */}
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.programType}>
            <FormLabel>{t("contactForm.labels.program")}</FormLabel>
            <Select
              {...register("programType", { required: true })}
              placeholder={t("contactForm.placeholders.program")}
            >
              <option value="gold">Gold</option>
              <option value="silver">Silver</option>
            </Select>
            {errors.programType && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Subject */}
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.subject}>
            <FormLabel>{t("contactForm.labels.subject")}</FormLabel>
            <Input
              {...register("subject", { required: true })}
              placeholder={t("contactForm.placeholders.subject")}
            />
            {errors.subject && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Message */}
        <GridItem colSpan={{ base: 1, sm: 2 }}>
          <FormControl isRequired isInvalid={!!errors.message}>
            <FormLabel>{t("contactForm.labels.message")}</FormLabel>
            <Textarea
              {...register("message", { required: true })}
              placeholder={t("contactForm.placeholders.message")}
              rows={4}
            />
            {errors.message && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.required")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Privacy / Terms */}
        <GridItem colSpan={{ base: 1, sm: 2 }}>
          <FormControl isRequired isInvalid={!!errors.privacy}>
            <Checkbox
              {...register("privacy", { required: true })}
              size="md"
              colorScheme="primary"
            >
              <Trans
                i18nKey="contactForm.labels.terms"
                // testo default o chiave su file di traduzione
                components={{
                  tagTC: (
                    <Link
                      color="blue.500"
                      href="/terms-and-conditions"
                      isExternal
                      textDecoration="underline"
                    >
                      {/* il testo "termini e condizioni" verrà sostituito qui automaticamente */}
                    </Link>
                  ),
                  tagPP: (
                    <Link
                      color="blue.500"
                      href="/privacy-policy"
                      isExternal
                      textDecoration="underline"
                    >
                      {/* il testo "privacy policy" verrà sostituito qui automaticamente */}
                    </Link>
                  ),
                }}
              />
            </Checkbox>
            {errors.privacy && (
              <FormErrorMessage>
                {t("contactForm.validationErrors.terms")}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* Submit button */}
        <GridItem colSpan={{ base: 1, sm: 2 }}>
          <Button
            type="submit"
            size="lg"
            width="100%"
            colorScheme="danger"
            isLoading={isLoading}
            borderRadius="xl"
            bg="danger.900"
          >
            {t("contactForm.labels.btn")}
          </Button>
        </GridItem>
      </Grid>
    </form>
  );
}
