import React from "react";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import enTranslation from "./Translations/en/translation.json";
import itTranslation from "./Translations/it/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import CountryFlag from "./Components/ReusableComponents/CountryFlag";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  HStack,
} from "@chakra-ui/react";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      it: {
        translation: itTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie"],
    },
  });

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        borderRadius="xl"
        p={2}
      >
        <CountryFlag
          countryCode={i18n.language.startsWith("it") ? "IT" : "GB"}
        />
      </MenuButton>
      <MenuList borderRadius="xl" shadow="md">
        <MenuItem onClick={() => changeLanguage("en")}>
          <HStack spacing={3}>
            <CountryFlag countryCode="GB" />
            <Text>English</Text>
          </HStack>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("it")}>
          <HStack spacing={3}>
            <CountryFlag countryCode="IT" />
            <Text>Italiano</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export { LanguageSwitcher };
