import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  Button,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { IconQrcode, IconHeartHandshake, IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Redesigned ProgramCard component without gradients
const ProgramCard = ({
  icon,
  title,
  subtitle,
  hints,
  description,
  buttonText,
  buttonAction,
  variant = "primary",
  bgColor, // provided for potential further use
  textColor, // provided for potential further use
}) => {
  // Set accent color based on the variant
  const accentColor = variant === "primary" ? "danger.900" : "warning.900";

  return (
    <Box
      p={4}
      borderRadius="xl"
      bg={useColorModeValue("white", "gray.700")}
      borderLeft="4px solid"
      borderColor={accentColor}
      boxShadow="md"
      transition="transform 0.2s ease, box-shadow 0.2s ease"
      _hover={{boxShadow: "lg" }}
    >
      <VStack spacing={4} align="stretch">
        <Box textAlign="center">
          <Box as={icon} boxSize={10} color={accentColor} mb={2} />
        </Box>
        <Heading as="h3" size="lg" color={textColor}>
          {title}
        </Heading>
        <Text fontSize="md" fontWeight="medium" color={accentColor}>
          {subtitle}
        </Text>
        <List spacing={2}>
          {hints.map((hint, index) => (
            <ListItem key={index} display="flex" alignItems="center">
              <ListIcon as={IconCheck} color={accentColor} />
              <Text fontSize="sm" color={textColor}>
                {hint}
              </Text>
            </ListItem>
          ))}
        </List>
        <Text fontSize="sm" color="gray.500" mt={2} whiteSpace="pre-line">
          {description}
        </Text>
        <Button
          mt={4}
          size="lg"
          bg={variant === "primary" ? "danger.900" : "warning.900"}
          variant={"solid"}
          rounded="xl"
          onClick={buttonAction}
          _hover={{ transform: "scale(1.01)" }}
        >
          {buttonText}
        </Button>
      </VStack>
    </Box>
  );
};

export default function Programs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Define colors based on the current color mode
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const headingColor = "primary.900";

  return (
    <Container maxW="container.xl" mb={12} id="programs" p={0}>
      <VStack spacing={8} align="start">
        <Heading as="h2" size="2xl" fontWeight="extrabold" lineHeight={"1.3"} textAlign={"center"} color={headingColor}>
          {t("homepage.programs.title")}
        </Heading>
        <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={8} w="100%">
          <ProgramCard
            icon={IconQrcode}
            title="Silver Program"
            subtitle={t("homepage.programs.silver.title")}
            hints={[
              t("homepage.programs.silver.hint-1"),
              t("homepage.programs.silver.hint-2"),
              t("homepage.programs.silver.hint-3"),
            ]}
            description={t("homepage.programs.silver.description")}
            buttonText={t("homepage.heroBanner.btn-1")}
            buttonAction={() => navigate("/signup")}
            variant="primary"
            bgColor={bgColor}
            textColor={textColor}
          />
          <ProgramCard
            icon={IconHeartHandshake}
            title="Gold Program"
            subtitle={t("homepage.programs.gold.title")}
            hints={[
              t("homepage.programs.gold.hint-1"),
              t("homepage.programs.gold.hint-2"),
              t("homepage.programs.gold.hint-3"),
            ]}
            description={t("homepage.programs.gold.description")}
            buttonText={t("homepage.programs.gold.btn")}
            buttonAction={() => navigate("/locker-onboarding")}
            variant="secondary"
            bgColor={bgColor}
            textColor={textColor}
          />
        </SimpleGrid>
      </VStack>
    </Container>
  );
}
