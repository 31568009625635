import React from "react";
import { Image } from "@chakra-ui/react";
import appLogo from "../../Images/App-logo-pink.png";

export default function ClosboxLogo() {
  return (
    <Image
      maxW="180px"
      borderRadius="md"
      src={appLogo}
      alt="Closbox Logo"
    />
  );
}