import React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import DashboardStats from "../MainDashboard/DashboardStats";
import QrCodeCard from "../MainDashboard/QrCodeCard";
import ShareBox from "../MainDashboard/ShareBox";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import {
  Container,
  Heading,
  Box,
  Grid,
  GridItem,
  Spinner,
  Center,
} from "@chakra-ui/react";
import CouponCard from "../MainDashboard/CouponCard";
import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const { userData, setUserData } = useUser();
  const [isLoading, setIsLoading] = React.useState(true);
  const [affiliationData, setAffiliationData] = React.useState(null);
  const { t } = useTranslation();

  const fetchAffiliateData = React.useCallback(() => {
    const closboxApi = Closbox.getInstance();
    closboxApi.setAuthToken(userData.token);

    closboxApi
      .syncAffiliate(userData.user.id)
      .then((response) => {
        const data = response.data;
        setUserData({
          user: userData.user,
          token: userData.token,
          company: data.company,
        });
        sessionStorage.setItem("affiliationData", JSON.stringify(data));
        setAffiliationData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        const savedData = sessionStorage.getItem("affiliationData");
        if (!savedData) {
          setIsLoading(false);
        }
      });
  }, [userData.token, userData.user, setUserData]);

  React.useEffect(() => {
    const savedData = sessionStorage.getItem("affiliationData");
    if (savedData) {
      setAffiliationData(JSON.parse(savedData));
    }
    fetchAffiliateData();
  }, [fetchAffiliateData]);

  let data = null;

  if (affiliationData) {
    data = [
      {
        icon: "qr",
        value: affiliationData.linkUsageCount,
        diff: "",
      },
      {
        icon: "earnings",
        value: new Intl.NumberFormat("it-IT", {
          style: "currency",
          currency: "EUR",
        }).format(affiliationData.totalCommissionCurrentMonth),
        diff: affiliationData.commissionChangePercentage,
      },
      {
        icon: "booking",
        value: affiliationData.totalBookingsCurrentMonth,
        diff: affiliationData.bookingsChangePercentage,
      },
    ];
  }

  if (isLoading || !data) {
    return (
      <Container h="100vh">
        <Center h="100%">
          <Box bg="white" p={6} borderRadius="xl" textAlign="center">
            <Spinner size="xl" color="danger.800" />
          </Box>
        </Center>
      </Container>
    );
  }

  return (
    <UserDashboardWrapper>
      <Container maxW="container.xl" px={{ base: 4, md: 6, lg: 8 }}>
        <Box mb={{ base: 6, md: 8 }}>
          <Heading as="h1" size="xl" color="primary.800">
            {t('dashboard.welcome', { name: userData.user.name.split(" ")[0] })}
          </Heading>
        </Box>

        <DashboardStats data={data} />
        
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
          gap={{ base: 4, md: 6, lg: 8 }}
          mt={{ base: 6, md: 8 }}
        >
          <GridItem>
            <CouponCard couponCode={affiliationData.couponCode} />
          </GridItem>
          <GridItem>
            <QrCodeCard qrCodeLink={affiliationData.qrCodeLink} />
          </GridItem>
          <GridItem>
            <ShareBox link={affiliationData.affiliationLink} />
          </GridItem>
        </Grid>
      </Container>
    </UserDashboardWrapper>
  );
}
