import React from "react";
import {
  Container,
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
  Input,
  Divider,
  useToast,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IconCheck, IconAlertHexagonFilled } from "@tabler/icons-react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import { useTranslation } from "react-i18next";

export default function Account() {
  const { userData, setUserData } = useUser();
  const [affiliationData, setAffiliationData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const savedData = sessionStorage.getItem("affiliationData");
  const closboxApi = Closbox.getInstance();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      // Account details
      affiliateName: userData.user.name || "",
      affiliateEmail: userData.user.email || "",
      affiliatePhone: userData.user.phone_number || "",

      // Company details
      companyName: userData.company.name || "",
      companyAddress: userData.company.address || "",
      companyCity: userData.company.city || "",
      companyCountry: userData.company.country || "",
      companyZipCode: userData.company.zip_code || "",
      companyLegalMail: userData.company.legal_mail || "",

      // Fiscal details
      vatNumber: userData.company.vat_number || "",
      fiscalCode: userData.company.fiscal_code || "",

      // Payment details
      ibanNumber: userData.company.iban || "",
      bicCode: userData.company.bic || "",
      bankName: userData.company.bank_name || "",
    },
  });

  const fetchAffiliateData = React.useCallback(() => {
    closboxApi.setAuthToken(userData.token);

    closboxApi
      .syncAffiliate(userData.user.id)
      .then((response) => {
        const data = response.data;
        setUserData({
          user: userData.user,
          token: userData.token,
          company: data.company,
        });
        sessionStorage.setItem("affiliationData", JSON.stringify(data));
        setAffiliationData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        if (!savedData) {
          setIsLoading(false);
        }
      });
  }, [userData.token, userData.user.id, setUserData, savedData]);

  React.useEffect(() => {
    if (savedData) {
      setAffiliationData(JSON.parse(savedData));
    }
    fetchAffiliateData();
  }, [savedData, fetchAffiliateData]);

  const toDashboard = (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };

  const saveDetails = async (values) => {
    setIsLoading(true);

    try {
      const response = await closboxApi.updateAffiliateData(
        values,
        userData.company.id,
        userData.user.id
      );

      if (response.message === "Ok") {
        setIsLoading(false);
        toast({
          title: t("account.toast.success.title"),
          description: t("account.toast.success.description"),
          status: "success",
          duration: 5000,
          isClosable: true,
          icon: <IconCheck />,
        });
        fetchAffiliateData();
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: t("account.toast.error.title"),
        description: t("account.toast.error.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
        icon: <IconAlertHexagonFilled />,
      });
      fetchAffiliateData();
    }
  };

  if (isLoading && !affiliationData) {
    return (
      <Container h="100vh">
        <Center h="100%">
          <Spinner size="xl" color="danger.800" />
        </Center>
      </Container>
    );
  }

  return (
    <UserDashboardWrapper>
      <Container maxW="container.xl">
        <Grid templateColumns="1fr" gap={6}>
          <GridItem>
            <Heading as="h1" size="xl" color="primary.800">
              {t("account.title")}
            </Heading>
          </GridItem>
        </Grid>

        <form onSubmit={handleSubmit(saveDetails)}>
          <Box bg="white" borderRadius="xl" p={6} my={6} shadow="md">
            <Heading as="h3" size="lg" color="primary.800" mb={6}>
              {t("account.accountDetails.title")}
            </Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.accountDetails.name.label")}</FormLabel>
                  <Input
                    placeholder={t("account.accountDetails.name.placeholder")}
                    size="lg"
                    {...register("affiliateName")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.accountDetails.email.label")}</FormLabel>
                  <Input
                    placeholder={t("account.accountDetails.email.placeholder")}
                    size="lg"
                    type="email"
                    {...register("affiliateEmail")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.accountDetails.phone.label")}</FormLabel>
                  <Input
                    placeholder={t("account.accountDetails.phone.placeholder")}
                    size="lg"
                    {...register("affiliatePhone")}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Box>

          <Box bg="white" borderRadius="xl" p={6} my={6} shadow="md">
            <Heading as="h3" size="lg" color="primary.800" mb={4}>
              {t("account.companyDetails.title")}
            </Heading>
            <Text color="gray.600" mb={6}>
              {t("account.companyDetails.subtitle")}
            </Text>

            <Divider my={6} />
            <Text fontWeight="bold" color="primary.800" mb={6}>
              {t("account.companyDetails.generalDetails")}
            </Text>

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.companyDetails.name.label")}</FormLabel>
                  <Input
                    placeholder={t("account.companyDetails.name.placeholder")}
                    size="lg"
                    {...register("companyName")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.companyDetails.address.label")}</FormLabel>
                  <Input
                    placeholder={t("account.companyDetails.address.placeholder")}
                    size="lg"
                    {...register("companyAddress")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.companyDetails.city.label")}</FormLabel>
                  <Input
                    placeholder={t("account.companyDetails.city.placeholder")}
                    size="lg"
                    {...register("companyCity")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.companyDetails.country.label")}</FormLabel>
                  <Input
                    placeholder={t("account.companyDetails.country.placeholder")}
                    size="lg"
                    {...register("companyCountry")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.companyDetails.zipCode.label")}</FormLabel>
                  <Input
                    placeholder={t("account.companyDetails.zipCode.placeholder")}
                    size="lg"
                    {...register("companyZipCode")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.companyDetails.legalMail.label")}</FormLabel>
                  <Input
                    placeholder={t("account.companyDetails.legalMail.placeholder")}
                    size="lg"
                    type="email"
                    {...register("companyLegalMail")}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider my={6} />
            <Text fontWeight="bold" color="primary.800" mb={6}>
              {t("account.fiscalDetails.title")}
            </Text>

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.fiscalDetails.vat.label")}</FormLabel>
                  <Input
                    placeholder={t("account.fiscalDetails.vat.placeholder")}
                    size="lg"
                    {...register("vatNumber")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.fiscalDetails.fiscalCode.label")}</FormLabel>
                  <Input
                    placeholder={t("account.fiscalDetails.fiscalCode.placeholder")}
                    size="lg"
                    {...register("fiscalCode")}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider my={6} />
            <Text fontWeight="bold" color="primary.800" mb={6}>
              {t("account.paymentDetails.title")}
            </Text>

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.paymentDetails.iban.label")}</FormLabel>
                  <Input
                    placeholder={t("account.paymentDetails.iban.placeholder")}
                    size="lg"
                    {...register("ibanNumber")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.paymentDetails.bic.label")}</FormLabel>
                  <Input
                    placeholder={t("account.paymentDetails.bic.placeholder")}
                    size="lg"
                    {...register("bicCode")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t("account.paymentDetails.bankName.label")}</FormLabel>
                  <Input
                    placeholder={t("account.paymentDetails.bankName.placeholder")}
                    size="lg"
                    {...register("bankName")}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Box>

          <Grid templateColumns={{ base: "1fr", lg: "repeat(2, 200px)" }} gap={4} justifyContent="end">
            <GridItem>
              <Button
                colorScheme="primary"
                variant="outline"
                size="lg"
                onClick={toDashboard}
                width="100%"
                borderRadius="full"
              >
                {t("account.buttons.backToDashboard")}
              </Button>
            </GridItem>
            <GridItem>
              <Button
                type="submit"
                colorScheme="primary"
                size="lg"
                width="100%"
                isLoading={isLoading}
                borderRadius="full"
              >
                {t("account.buttons.saveDetails")}
              </Button>
            </GridItem>
          </Grid>
        </form>
      </Container>
    </UserDashboardWrapper>
  );
}
