import React from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import {
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Progress,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  IconButton,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

function PasswordRequirement({ meets, label }) {
  return (
    <HStack spacing={2} mt={2}>
      {meets ? (
        <IconCheck size="0.9rem" color="green.500" />
      ) : (
        <IconX size="0.9rem" color="red.500" />
      )}
      <Text fontSize="sm" color={meets ? "green.500" : "red.500"}>
        {label}
      </Text>
    </HStack>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((req) => {
    if (!req.re.test(password)) {
      multiplier += 1;
    }
  });

  // Garantiamo almeno il 10% per "visualizzare" qualcosa
  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

function PasswordWithMeter({ value: initialValue = "", onChange }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);
  const [show, setShow] = React.useState(false);

  // Ref per l’input
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const handleInputChange = (e) => setValue(e.target.value);
  const handleToggleShow = () => setShow(!show);

  // Mappa delle requirement
  const checks = requirements.map((req, i) => (
    <PasswordRequirement key={i} label={req.label} meets={req.re.test(value)} />
  ));

  const strength = getStrength(value);
  const colorScheme =
    strength === 100 ? "green" : strength > 50 ? "yellow" : "red";

  return (
    <Box>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom"
        trapFocus={false}
        autoFocus={false}
        closeOnEsc={true}
      >
        <PopoverTrigger>
          <Box>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <InputGroup size="lg">
                <Input
                  ref={inputRef}
                  type={show ? "text" : "password"}
                  placeholder="Your password"
                  value={value}
                  onChange={handleInputChange}
                  // Apri il popover quando l'utente inizia a digitare
                  onKeyDown={() => setIsOpen(true)}
                  // Chiudi il popover quando l'input perde il focus
                  onBlur={() => setIsOpen(false)}
                  borderRadius="md"
                  isRequired
                  pr="4.5rem"
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    onClick={handleToggleShow}
                    onMouseDown={(e) => e.preventDefault()}
                    icon={show ? <ViewOffIcon /> : <ViewIcon />}
                    variant="ghost"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Box>
        </PopoverTrigger>

        <PopoverContent p={4}>
          <Progress
            value={strength}
            colorScheme={colorScheme}
            size="sm"
            mb={4}
          />
          <VStack align="stretch" spacing={1}>
            <PasswordRequirement
              label="Includes at least 6 characters"
              meets={value.length > 5}
            />
            {checks}
          </VStack>

          {/* Pulsante per chiudere il popover (opzionale) */}
          <Button size="sm" mt={4} onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default PasswordWithMeter;
