import React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import { parseISO, formatDistanceToNow } from "date-fns";
import noRecords from "../../Images/Illustrations/no-records.svg";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  Text,
  Badge,
  Table,
  Tbody,
  Tr,
  Td,
  Grid,
  GridItem,
  Image,
  Card,
  CardBody,
  Button,
  Spinner,
  Center,
  Avatar,
  HStack,
  ButtonGroup,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function Bookings() {
  const { userData, setUserData } = useUser();
  const [affiliationData, setAffiliationData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchAffiliateData = React.useCallback(() => {
    const closboxApi = Closbox.getInstance();
    closboxApi.setAuthToken(userData.token);

    closboxApi
      .syncAffiliate(userData.user.id)
      .then((response) => {
        const data = response.data;
        setUserData({
          user: userData.user,
          token: userData.token,
          company: data.company,
        });
        sessionStorage.setItem("affiliationData", JSON.stringify(data));
        setAffiliationData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        const savedData = sessionStorage.getItem("affiliationData");
        if (!savedData) {
          setIsLoading(false);
        }
      });
  }, [userData.token, userData.user, setUserData]);

  React.useEffect(() => {
    const savedData = sessionStorage.getItem("affiliationData");
    if (savedData) {
      setAffiliationData(JSON.parse(savedData));
    }
    fetchAffiliateData();
  }, [fetchAffiliateData]);

  React.useEffect(() => {
    const totalRecords = affiliationData
      ? affiliationData.allCommissionRecords.length
      : 0;
    setTotalPages(Math.ceil(totalRecords / ITEMS_PER_PAGE));
  }, [affiliationData]);

  let data = affiliationData?.allCommissionRecords || null;

  const statusColors = {
    created: "primary",
    confirmed: "yellow",
    cancelled: "red",
    paid: "green",
  };

  if (isLoading || !data) {
    return (
      <Container h="100vh">
        <Center h="100%">
          <Spinner size="xl" color="danger.800" />
        </Center>
      </Container>
    );
  }

  // Calculate which records to display for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentRecords = data.slice(startIndex, endIndex);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const rows = currentRecords.map((record) => (
    <Tr key={record.booking_id}>
      <Td>
        <HStack spacing={4}>
          <Avatar size="sm" name="🚀" />
          <Box>
            <Text color="primary.800" fontWeight="500">
              {t("bookings.table.booking.title")}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {t("bookings.table.booking.createdAt", { time: formatDistanceToNow(parseISO(record.created_at)) })}
            </Text>
          </Box>
        </HStack>
      </Td>

      <Td>
        <Badge
          colorScheme={statusColors[record.status.toLowerCase()]}
          variant="outline"
        >
          {record.status}
        </Badge>
      </Td>

      <Td>
        <Text color="primary.800" fontSize="sm">
          {t("bookings.table.amount.title")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          {formatAmount(record.booking_total_amount)}
        </Text>
      </Td>

      <Td>
        <Text color="primary.800" fontSize="sm">
          {t("bookings.table.amount.earnings")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          + {formatAmount(record.commission_amount)}
        </Text>
      </Td>

      <Td>
        <Text color="primary.800" fontSize="sm">
          {t("bookings.table.amount.commission")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          {record.percentage_applied} %
        </Text>
      </Td>
    </Tr>
  ));

  const toDashboard = (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };

  if (data.length === 0) {
    return (
      <UserDashboardWrapper>
        <Container maxW="container.xl" h="100%">
          <Card>
            <CardBody>
              <VStack spacing={6}>
                <Image
                  maxW="500px"
                  mx="auto"
                  src={noRecords}
                  alt="No records"
                />
                <Heading as="h1" size="xl" color="primary.800">
                  {t("bookings.noRecords.title")}
                </Heading>
                <Text color="gray.600">
                  {t("bookings.noRecords.description")}
                </Text>
                <Button
                  colorScheme="danger"
                  size="lg"
                  borderRadius="full"
                  onClick={toDashboard}
                >
                  {t("bookings.noRecords.button")}
                </Button>
              </VStack>
            </CardBody>
          </Card>
        </Container>
      </UserDashboardWrapper>
    );
  }

  return (
    <UserDashboardWrapper>
      <Container maxW="container.xl">
        <Grid templateColumns="1fr" gap={6}>
          <GridItem>
            <Heading as="h1" size="xl" color="primary.800">
              {t("bookings.title")}
            </Heading>
          </GridItem>
        </Grid>

        <Box
          overflowX="auto"
          bg="white"
          mt={6}
          borderRadius="xl"
          shadow="md"
        >
          <Table variant="simple" minW="800px">
            <Tbody>{rows}</Tbody>
          </Table>
        </Box>

        <Flex justify="center" mt={6}>
          <ButtonGroup>
            {[...Array(totalPages)].map((_, i) => (
              <Button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                colorScheme={currentPage === i + 1 ? "danger" : "gray"}
                variant={currentPage === i + 1 ? "solid" : "outline"}
              >
                {i + 1}
              </Button>
            ))}
          </ButtonGroup>
        </Flex>
      </Container>
    </UserDashboardWrapper>
  );
}
