import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Image,
  Flex,
  Stack,
  Icon,
  chakra,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { IconCheck, IconArrowRight, IconChartBar, IconUsers, IconClock } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SeoHelmet from "../../Seo/SeoHelmet";
import Navbar from "../../ReusableComponents/Navbar";
import Footer from "../../ReusableComponents/Footer";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const scaleIn = keyframes`
  from { transform: scale(0.95); }
  to { transform: scale(1); }
`;

const StatCard = ({ title, stat, icon, delay }) => {
  const animation = `${fadeIn} 0.6s ease-out ${delay}s forwards`;
  const bg = useColorModeValue("white", "gray.700");
  
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={5}
      bg={bg}
      shadow="xl"
      border="1px solid"
      borderColor={useColorModeValue("gray.100", "gray.500")}
      rounded="xl"
      opacity="0"
      animation={animation}
      transition="all 0.3s ease"
      _hover={{ transform: "translateY(-5px)", shadow: "2xl" }}
    >
      <Flex justifyContent="space-between">
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight="medium" fontSize={"h3"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize="2xl" fontWeight="bold" color="primary.800">
            {stat}
          </StatNumber>
        </Box>
        <Box my="auto" color="primary.800" alignContent="center">
          <Icon as={icon} w={8} h={8} color={"danger.900"}/>
        </Box>
      </Flex>
    </Stat>
  );
};

const Feature = ({ title, text, icon, delay }) => {
  const animation = `${fadeIn} 0.6s ease-out ${delay}s forwards`;
  
  return (
    <Flex
      bg={useColorModeValue("white", "gray.700")}
      rounded="xl"
      p={{ base: 4, sm: 6, md: 8 }}
      spacing={{ base: 4 }}
      maxW="lg"
      opacity="0"
      animation={animation}
      transition="all 0.3s ease"
      _hover={{ transform: "scale(1.02)", shadow: "2xl" }}
      border="1px solid"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      shadow="xl"
      textAlign={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      
    >
      <Flex
        w={16}
        h={16}
        align="center"
        justify="center"
        color="white"
        rounded="full"
        bg="danger.900"
        mb={4}
      >
        {icon}
      </Flex>
      <Box>
        <chakra.h3 fontSize="xl" fontWeight="bold" color="primary.900" mb={2}>
          {title}
        </chakra.h3>
        <Text color={useColorModeValue("gray.500", "gray.200")}>{text}</Text>
      </Box>
    </Flex>
  );
};

export default function SilverProgramPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "white");

  const footerLinks = [
    {
      label: t("termsConditions.title"),
      link: "/terms-and-conditions",
      className: "iubenda-nostyle no-brand iubenda-embed",
    },
    {
      label: "Privacy Policy",
      link: "https://www.iubenda.com/privacy-policy/",
      className: "iubenda-nostyle no-brand iubenda-embed",
    },
  ];

  return (
    <Box bg={bgColor} minH="100vh">
      <SeoHelmet
        path="/mkt/silver-program"
        title={t("homepage.programs.silver.title")}
        description={t("homepage.programs.silver.description")}
      />
      <Navbar />

      {/* Hero Section with Gradient */}
      <Box
        w="full"
        position="relative"
        bg="white"
        minH={{ base: "auto", md: "auto" }}
        mt="60px"
        py={{ base: "8", md: "16" }}
        overflow="hidden"
      >
        <Container maxW="container.xl" position="relative" zIndex={1}>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 8, md: 16 }}
            align="center"
            justify="center"
            textAlign={{ base: "left", md: "center" }}
          >
            <VStack
              align={{ base: "center", md: "flex-start" }}
              spacing={6}
              maxW={{ base: "100%", md: "45%" }}
              textAlign={{ base: "center", md: "left" }}
              sx={{ animation: `${fadeIn} 0.6s ease-out` }}
            >
              <Heading
                as="h1"
                fontSize={{ base: "4xl", md: "5xl" }}
                fontWeight="extrabold"
                lineHeight="1.2"
                color="primary.900"
              >
                {t("homepage.programs.silver.title")}
              </Heading>
              <Text fontSize="xl" color="gray.600" opacity={0.9}>
                {t("homepage.programs.silver.description")}
              </Text>
              <Button
                size="lg"
                bg="primary.900"
                rightIcon={<IconArrowRight />}
                borderRadius={"xl"}
                onClick={() => navigate("/signup")}
                transition="all 0.3s ease"
                _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
              >
                {t("homepage.heroBanner.btn-1")}
              </Button>
            </VStack>
            <Box
              maxW={{ base: "100%", md: "500px" }}
              overflow="hidden"
              borderRadius="xl"
              mt={{ base: 4, md: 0 }}
              mx="auto"
            >
              <Image
                src={require("../../../Images/silver/hero.png")}
                alt="Digital Partnership"
                objectFit="contain"
                w="full"
                h="auto"
                maxH={{ base: "300px", md: "400px" }}
              />
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Stats Section */}
      <Box pb={20} bg={useColorModeValue("white", "gray.800")}>
        <Container maxW="container.xl">
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
            <StatCard
              title={t("marketing.silver.stats.earnings")}
              stat="€500+"
              icon={IconChartBar}
              delay={0.3}
            />
            <StatCard
              title={t("marketing.silver.stats.commission")}
              stat="10%"
              icon={IconUsers}
              delay={0.5}
            />
            <StatCard
              title={t("marketing.silver.stats.setup")}
              stat="5 min"
              icon={IconClock}
              delay={0.7}
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <Box
              textAlign="center"
              sx={{ animation: `${fadeIn} 0.6s ease-out` }}
            >
              <Heading
                fontSize={{ base: "3xl", sm: "4xl" }}
                fontWeight="extrabold"
                mb={4}
              >
                {t("marketing.silver.features.title")}
              </Heading>
              <Text fontSize="xl" color="gray.500" maxW="2xl" mx="auto">
                {t("marketing.silver.features.subtitle")}
              </Text>
            </Box>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={10}
              px={{ base: 4, md: 10 }}
            >
              <Feature
                icon={<Icon as={IconCheck} w={10} h={10} />}
                title={t("marketing.silver.features.card-1.title")}
                text={t("marketing.silver.features.card-1.content")}
                delay={0.3}
              />
              <Feature
                icon={<Icon as={IconCheck} w={10} h={10} />}
                title={t("marketing.silver.features.card-2.title")}
                text={t("marketing.silver.features.card-2.content")}
                delay={0.5}
              />
              <Feature
                icon={<Icon as={IconCheck} w={10} h={10} />}
                title={t("marketing.silver.features.card-3.title")}
                text={t("marketing.silver.features.card-3.content")}
                delay={0.7}
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* Benefits Section with Image */}
      <Box py={20} bg={useColorModeValue("white", "gray.800")}>
        <Container maxW="container.xl">
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Box sx={{ animation: `${fadeIn} 0.6s ease-out` }}>
              <Box maxW={{ base: "100%", md: "500px" }} mx="auto">
                <Image
                  rounded="xl"
                  alt="Benefits"
                  src={require("../../../Images/silver/program.png")}
                  objectFit="contain"
                  w="full"
                  h="auto"
                  maxH={{ base: "300px", md: "500px" }}
                />
              </Box>
            </Box>
            <VStack
              align="start"
              spacing={6}
              justify="center"
              sx={{ animation: `${fadeIn} 0.6s ease-out 0.3s both` }}
            >
              <Heading size="xl" mb={4}>
                {t("marketing.silver.benefits.title")}
              </Heading>
              <Text fontSize="lg" color="gray.500" mb={4}>
                {t("marketing.silver.benefits.subtitle")}
              </Text>
              <List spacing={5}>
                {t("marketing.silver.benefits.list.items", {
                  returnObjects: true,
                }).map((benefit, index) => (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                    fontSize="lg"
                  >
                    <ListIcon as={IconCheck} color="primary.800" w={6} h={6} />
                    <Text>{benefit}</Text>
                  </ListItem>
                ))}
              </List>
              <Button
                size="lg"
                bg="danger.900"
                rounded={"xl"}
                mt={4}
                rightIcon={<IconArrowRight />}
                onClick={() => navigate("/signup")}
                transition="all 0.3s ease"
                _hover={{ transform: "translateY(-2px)", shadow: "lg" }}
              >
                {t("homepage.heroBanner.btn-1")}
              </Button>
            </VStack>
          </SimpleGrid>
        </Container>
      </Box>

      {/* Final CTA Section */}
      <Box py={20}>
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={10}
            align="center"
            justify="space-between"
            bg="primary.800"
            p={10}
            rounded="xl"
            shadow="2xl"
            sx={{ animation: `${fadeIn} 0.6s ease-out` }}
          >
            <VStack
              align="start"
              spacing={4}
              maxW={{ base: "100%", md: "60%" }}
            >
              <Heading
                color="white"
                size="xl"
                textAlign={{ base: "left", md: "center" }}
              >
                {t("marketing.silver.cta.title")}
              </Heading>
              <Text
                color="white"
                fontSize="lg"
                opacity={0.9}
                textAlign={{ base: "left", md: "center" }}
              >
                {t("marketing.silver.cta.subtitle")}
              </Text>
            </VStack>
            <Button
              size="lg"
              bg="danger.900"
              rounded={"xl"}
              rightIcon={<IconArrowRight />}
              onClick={() => navigate("/signup")}
              transition="all 0.3s ease"
              _hover={{ transform: "translateY(-2px)", shadow: "lg" }}
            >
              {t("homepage.heroBanner.btn-1")}
            </Button>
          </Stack>
        </Container>
      </Box>

      <Footer links={footerLinks} />
    </Box>
  );
}