import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SeoHelmet = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname.slice(1); // Rimuove la barra iniziale

  const title = t(`seo.${path}.title`, t('seo..title')); // Fallback to homepage title if path not found
  const description = t(`seo.${path}.description`, t('seo..description')); // Fallback to homepage description if path not found

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SeoHelmet;
