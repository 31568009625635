import React from "react";
import {
  Input,
  Checkbox,
  Heading,
  Text,
  Button,
  Select,
  Alert,
  AlertIcon,
  Box,
  Container,
  VStack,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Closbox from "../../Api/Closbox";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import { ChevronRightIcon, ChevronLeftIcon, WarningIcon } from "@chakra-ui/icons";
import PasswordWithMeter from "../ReusableComponents/PasswordInputWithMeter";
import Navbar from "../ReusableComponents/Navbar";
import { useTranslation } from "react-i18next";

export default function RegisterForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const { setUserData } = useUser();
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate();
  const toast = useToast();
  const [formValues, setFormValues] = React.useState({
    privacyPolicy: false,
    termsAndConditions: false,
  });
  const [step, setStep] = React.useState(1);
  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const [error, setError] = React.useState();
  const [companyCoreBusiness, setCompanyCoreBusiness] = React.useState(null);
  const { t } = useTranslation();

  const availableLanguages = [
    { value: "it", label: "Italian" },
    { value: "en", label: "English" },
    { value: "es", label: "Espanol" },
    { value: "fr", label: "Francais" },
    { value: "cn", label: "Chinese" },
    { value: "ru", label: "Russian" },
  ];

  const companyType = [
    { value: "bar_pub", label: "Bar or Pub" },
    { value: "restaurant_cafe", label: "Restaurant or Cafe" },
    {
      value: "accommodation_services",
      label: "Accommodation Services (Hotel/B&B/Motel/Vacation Rentals)",
    },
    {
      value: "travel_services",
      label: "Travel Services (Agency, Tour Operator)",
    },
    {
      value: "digital_influencers",
      label: "Digital Influencers (Bloggers, Vloggers, Social Media Personalities)",
    },
    { value: "tour_guide", label: "Tour Guide Services" },
    {
      value: "outdoor_adventures",
      label: "Outdoor Adventures and Recreations",
    },
    { value: "transport_rental", label: "Transport Rental Services" },
    { value: "event_planning", label: "Event Planning and Organizing" },
    {
      value: "educational_services",
      label: "Educational or Workshop Services",
    },
    { value: "visitor_center", label: "Visitor or Information Center" },
    { value: "sports_facility", label: "Sports Facility" },
    { value: "shopping_center", label: "Shopping Center" },
    { value: "service_apartment", label: "Service Apartment" },
    { value: "conference_center", label: "Conference or Convention Center" },
    { value: "Others", label: "Other activities type" },
  ];

  const handleInputChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  };

  const handlePasswordChange = (passwordValue) => {
    setFormValues({
      ...formValues,
      password: passwordValue,
    });
  };

  function validateForm() {
    let localErrors = {};
    if (step === 1) {
      if (!formValues.name) localErrors.name = t("register.validation.required.name");
      if (!formValues.email) localErrors.email = t("register.validation.required.email");
      if (!formValues.password) localErrors.password = t("register.validation.required.password");
      if (!formValues.phoneNumber) localErrors.phoneNumber = t("register.validation.required.phone");
      if (!selectedLanguage) localErrors.language = t("register.validation.required.language");
    } else {
      if (!companyCoreBusiness) localErrors.companyCoreBusiness = t("register.validation.required.business");
      if (!formValues.shop_name) localErrors.shop_name = t("register.validation.required.shopName");
      if (!formValues.shop_address) localErrors.shop_address = t("register.validation.required.shopAddress");
      if (!formValues.privacyPolicy) {
        localErrors.privacyPolicy = t("register.validation.terms.privacy");
      }
      if (!formValues.termsAndConditions) {
        localErrors.termsAndConditions = t("register.validation.terms.conditions");
      }
    }
    return localErrors;
  }

  const previousStep = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const localErrors = validateForm();

    if (Object.keys(localErrors).length === 0) {
      if (step === 1) {
        setStep(2);
        setErrors({});
        setIsLoading(false);
      } else if (step === 2) {
        const closboxApi = Closbox.getInstance();

        let submittedData = {
          name: formValues.name,
          phoneNumber: formValues.phoneNumber,
          email: formValues.email,
          password: formValues.password,
          role: 4,
          languages: [selectedLanguage], // Send single language as array
          // New fields
          shop_name: formValues.shop_name,
          shop_address: formValues.shop_address,
          website: formValues.website,
          // Placeholder values for removed fields
          companyName: "To be updated",
          companyAddress: "To be updated",
          city: "To be updated",
          zipCode: "00000",
          country: "To be updated",
          vat: "To be updated",
          fiscalCode: "To be updated",
          legalMail: "pending@update.com",
          companyEmail: "pending@update.com",
          type: companyCoreBusiness,
          termsAndConditions: formValues.termsAndConditions,
          privacyPolicy: formValues.privacyPolicy,
        };

        try {
          const response = await closboxApi.register(submittedData);
          if (response.statusCode === 200 && response.message === "Registration successfull") {
            const loginResponse = await closboxApi.login(formValues.email, formValues.password, "affiliates");
            if (loginResponse.token && loginResponse.user) {
              closboxApi.setAuthToken(loginResponse.token);
              setUserData({ user: loginResponse.user, token: loginResponse.token });
              navigate("/dashboard");
            }
          }
        } catch (error) {
          setError(t("register.errors.registration"));
          toast({
            title: "Error",
            description: t("register.errors.registration"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setErrors(localErrors);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="100vh" py={10} px={4}>
        <Container maxW="container.md">
          <Box mt={14} marginBottom={5}>
            <Heading
              color="primary.800"
              fontSize={{ base: "2xl", md: "4xl" }}
              mb={4}
            >
              {t(step === 1 ? "register.step1.title" : "register.step2.title")}
            </Heading>

            <Text color="gray.600" fontSize="md" style={{ marginTop: "15px" }}>
              {t(
                step === 1
                  ? "register.step1.subtitle"
                  : "register.step2.subtitle"
              )}
            </Text>

            <form style={{ marginTop: "15px" }}>
              <VStack spacing={6}>
                {step === 1 ? (
                  // Step 1 Form Fields
                  <>
                    <FormControl isRequired isInvalid={errors.name}>
                      <FormLabel>{t("register.step1.fullName")}</FormLabel>
                      <Input
                        name="name"
                        placeholder={t("contactForm.placeholders.name")}
                        size="lg"
                        onChange={handleInputChange}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.phoneNumber}>
                      <FormLabel>{t("register.step1.phoneNumber")}</FormLabel>
                      <Input
                        name="phoneNumber"
                        placeholder={t("register.step1.phoneNumberPlaceholder")}
                        size="lg"
                        onChange={handleInputChange}
                      />
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.language}>
                      <FormLabel>
                        {t("register.step1.preferredLanguage")}
                      </FormLabel>
                      <Select
                        placeholder={t("register.step1.selectLanguage")}
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        size="lg"
                      >
                        {availableLanguages.map((lang) => (
                          <option key={lang.value} value={lang.value}>
                            {lang.label}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{errors.language}</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.email}>
                      <FormLabel>{t("register.step1.email")}</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        placeholder={t("register.step1.emailPlaceholder")}
                        size="lg"
                        onChange={handleInputChange}
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.password}>
                      <PasswordWithMeter
                        value={formValues.password}
                        onChange={handlePasswordChange}
                      />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                  </>
                ) : (
                  // Step 2 Form Fields
                  <>
                    <FormControl
                      isRequired
                      isInvalid={errors.companyCoreBusiness}
                    >
                      <FormLabel>{t("register.step2.businessType")}</FormLabel>
                      <Select
                        placeholder={t("register.step2.selectBusiness")}
                        size="lg"
                        value={companyCoreBusiness}
                        onChange={(e) => setCompanyCoreBusiness(e.target.value)}
                      >
                        {companyType.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.companyCoreBusiness}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.shop_name}>
                      <FormLabel>{t("register.step2.shopName")}</FormLabel>
                      <Input
                        name="shop_name"
                        placeholder={t("register.step2.shopNamePlaceholder")}
                        size="lg"
                        onChange={handleInputChange}
                      />
                      <FormErrorMessage>{errors.shop_name}</FormErrorMessage>
                      <Text fontSize="sm" color="gray.600" mt={1}>
                        {t("register.step2.shopNameHint")}
                      </Text>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.shop_address}>
                      <FormLabel>{t("register.step2.shopAddress")}</FormLabel>
                      <Input
                        name="shop_address"
                        placeholder={t("register.step2.shopAddressPlaceholder")}
                        size="lg"
                        onChange={handleInputChange}
                      />
                      <FormErrorMessage>{errors.shop_address}</FormErrorMessage>
                    </FormControl>

                    <FormControl>
                      <FormLabel>{t("register.step2.website")}</FormLabel>
                      <Input
                        name="website"
                        placeholder={t("register.step2.websitePlaceholder")}
                        size="lg"
                        onChange={handleInputChange}
                      />
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.privacyPolicy}>
                      <Checkbox
                        name="privacyPolicy"
                        onChange={handleInputChange}
                        colorScheme="primary"
                      >
                        {t("register.step2.privacyPolicy")}
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.privacyPolicy}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isRequired
                      isInvalid={errors.termsAndConditions}
                    >
                      <Checkbox
                        name="termsAndConditions"
                        onChange={handleInputChange}
                        colorScheme="primary"
                      >
                        {t("register.step2.termsAndConditions")}
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.termsAndConditions}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}

                {(Object.keys(errors).length > 0 || error) && (
                  <Alert status="error" borderRadius="md">
                    <AlertIcon as={WarningIcon} />
                    <Text>{error || t("register.errors.generic")}</Text>
                  </Alert>
                )}

                <Flex
                  width="100%"
                  justify="flex-end"
                  gap={4}
                  direction={{ base: "column", sm: "row" }}
                >
                  {step === 2 && (
                    <Button
                      variant="outline"
                      colorScheme="primary"
                      size="lg"
                      onClick={previousStep}
                      leftIcon={<ChevronLeftIcon />}
                      width={{ base: "100%", sm: "auto" }}
                    >
                      {t("register.step2.previous")}
                    </Button>
                  )}
                  <Button
                    colorScheme="primary"
                    size="lg"
                    onClick={handleClick}
                    isLoading={isLoading}
                    rightIcon={step === 1 ? <ChevronRightIcon /> : undefined}
                    width={{ base: "100%", sm: "auto" }}
                  >
                    {t(
                      step === 1
                        ? "register.step1.next"
                        : "register.step2.create"
                    )}
                  </Button>
                </Flex>
              </VStack>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
}
