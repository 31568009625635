import React from "react";
import {
  Container,
  Grid,
  GridItem,
  Image,
  Heading,
  VStack,
  Badge,
  Center,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import image1 from "../../Images/Shop1.jpg";
import image2 from "../../Images/Shop_M.webp";
import image3 from "../../Images/Shop_L.jpg";
import image4 from "../../Images/Shop_XL.webp";
import { useTranslation } from "react-i18next";

const PRIMARY_COL_HEIGHT = "300px";

export default function PicturesGrid() {
  const { t } = useTranslation();
  const SECONDARY_COL_HEIGHT = "142px";

  // Componente ImageBox con overlay scuro e zoom al passaggio del mouse
  const ImageBox = ({ src, alt, height }) => (
    <Box
      overflow="hidden"
      borderRadius="xl"
      height={height}
      position="relative"
      role="group"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{ transform: "scale(1.02)", boxShadow: "xl" }}
    >
      <Box
        position="absolute"
        inset="0"
        bg="blackAlpha.600"
        opacity="0"
        transition="opacity 0.3s ease"
        _groupHover={{ opacity: 1 }}
        zIndex={1}
      />
      <Image
        src={src}
        alt={alt}
        width="100%"
        height="100%"
        objectFit="cover"
        transition="transform 0.3s ease"
        _groupHover={{ transform: "scale(1.1)" }}
      />
    </Box>
  );

  return (
    <Container maxW="container.xl">
      <VStack spacing={8}>
        <Center>
          <Badge
            colorScheme="success"
            px={6}
            py={2}
            borderRadius="full"
            fontSize="md"
            textTransform="uppercase"
            letterSpacing="wider"
            boxShadow="md"
            transition="transform 0.3s ease"
            _hover={{ transform: "translateY(-2px)" }}
          >
            {t("homepage.shopGrid.badge")}
          </Badge>
        </Center>
        <Heading
          as="h2"
          fontSize={{ base: "3xl", md: "5xl" }}
          textAlign="center"
          color="primary.900"
          fontWeight="extrabold"
          letterSpacing="tight"
          lineHeight="shorter"
          maxW="800px"
          position="relative"
          _after={{
            content: '""',
            display: "block",
            width: "100px",
            height: "4px",
            bgGradient: "linear(to-r, success.500, success.300)",
            borderRadius: "full",
            mx: "auto",
            mt: 4,
            mb:6,
          }}
        >
          {t("homepage.shopGrid.title")}
        </Heading>
      </VStack>
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
        {/* Colonna sinistra: immagine grande */}
        <GridItem>
          <ImageBox src={image3} alt="Shop view" height={PRIMARY_COL_HEIGHT} />
        </GridItem>
        {/* Colonna destra: griglia di immagini più piccole */}
        <GridItem>
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={6}
            height={PRIMARY_COL_HEIGHT}
          >
            <GridItem colSpan={2}>
              <ImageBox
                src={image1}
                alt="Shop view"
                height={SECONDARY_COL_HEIGHT}
              />
            </GridItem>
            <GridItem>
              <ImageBox
                src={image2}
                alt="Shop view"
                height={SECONDARY_COL_HEIGHT}
              />
            </GridItem>
            <GridItem>
              <ImageBox
                src={image4}
                alt="Shop view"
                height={SECONDARY_COL_HEIGHT}
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Container>
  );
}
