import React from "react";
import {
  Badge,
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Center,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { IconClick, IconCoin, IconHelpHexagon } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function ProgramFeatures() {
  const { t } = useTranslation();

  const features = [
    {
      title: t("homepage.programFeatures.card-1.title"),
      description: t("homepage.programFeatures.card-1.content"),
      icon: IconClick,
      gradient: "linear(to-r, danger.500, danger.700)",
    },
    {
      title: t("homepage.programFeatures.card-2.title"),
      description: t("homepage.programFeatures.card-2.content"),
      icon: IconHelpHexagon,
      gradient: "linear(to-r, primary.500, primary.700)",
    },
    {
      title: t("homepage.programFeatures.card-3.title"),
      description: t("homepage.programFeatures.card-3.content"),
      icon: IconCoin,
      gradient: "linear(to-r, green.400, green.600)",
    },
  ];

  const FeatureCard = ({ title, description, icon, gradient }) => (
    <Box
      p={6}
      borderRadius="2xl"
      bg={useColorModeValue("whiteAlpha.900", "gray.700")}
      backdropFilter="blur(8px)"
      boxShadow="xl"
      position="relative"
      overflow="hidden"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{ transform: "translateY(-6px)", boxShadow: "2xl" }}
    >
      {/* Linea di evidenza */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        height="4px"
        bgGradient={gradient}
        borderRadius="full"
      />
      <VStack spacing={4} align="start" mt={4}>
        <Center
          w={16}
          h={16}
          borderRadius="full"
          bgGradient={gradient}
          boxShadow="lg"
        >
          <Icon as={icon} boxSize={8} color="white" />
        </Center>
        <Heading
          as="h3"
          size="md"
          fontWeight="700"
          color={"primary.900"}
        >
          {title}
        </Heading>
        <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
          {description}
        </Text>
      </VStack>
    </Box>
  );

  return (
    <Container maxW="container.xl" py={20}>
      <VStack spacing={8}>
        <Badge
          colorScheme="danger"
          px={6}
          py={2}
          borderRadius="full"
          fontSize="md"
          textTransform="uppercase"
          letterSpacing="wider"
          boxShadow="md"
          transition="transform 0.3s ease"
          _hover={{ transform: "translateY(-2px)" }}
        >
          {t("homepage.programFeatures.badge")}
        </Badge>
        <Heading
          as="h2"
          fontSize={{ base: "3xl", md: "5xl" }}
          fontWeight="900"
          textAlign="center"
          bgGradient="primary.900"
          letterSpacing="tight"
          lineHeight="shorter"
        >
          {t("homepage.programFeatures.title")}
        </Heading>
        <Text
          textAlign="center"
          maxW="800px"
          fontSize={{ base: "lg", md: "xl" }}
          color={useColorModeValue("gray.600", "gray.300")}
        >
          {t("homepage.programFeatures.subtitle")}
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="100%">
          {features.map((feature) => (
            <FeatureCard key={feature.title} {...feature} />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
}
