import React from "react";
import {
  Box,
  Container,
  useDisclosure,
  SlideFade,
  ScaleFade,
} from "@chakra-ui/react";
import HeroBanner from "../WelcomePage/HeroBanner";
import ProgramFeatures from "../WelcomePage/ProgramFeatures";
import Programs from "../WelcomePage/Programs";
import ShopGrid from "../WelcomePage/ShopGrid";
import Contact from "../WelcomePage/Contact";
import Faqs from "../WelcomePage/Faqs";
import Navbar from "../ReusableComponents/Navbar";
import Footer from "../ReusableComponents/Footer";

export default function WelcomePage() {
  const { isOpen } = useDisclosure({ defaultIsOpen: true });

  const footerLinks = [
    {
      label: "Privacy Policy",
      link: "https://www.iubenda.com/privacy-policy/",
      className: "iubenda-nostyle no-brand iubenda-embed",
    },
    {
      label: "Cookie Policy",
      link: "https://www.iubenda.com/privacy-policy/cookie-policy/",
      className: "iubenda-nostyle no-brand iubenda-embed",
    },
    {
      label: "Terms and Conditions",
      link: "/terms",
    },
  ];

  return (
    <>
      <Navbar />
      <Box 
        as="main" 
        position="relative"
        overflow="hidden"
        w={"full"}
        pt="60px" // Account for fixed navbar height
      >
        {/* Hero Section with entrance animation */}
        <SlideFade in={isOpen} offsetY="20px">
          <HeroBanner />
        </SlideFade>

        {/* Features Section */}
        <Box
          bg="gray.50"
          py={10}
        >
          <ScaleFade in={isOpen} initialScale={0.9}>
            <ProgramFeatures />
          </ScaleFade>
        </Box>

        {/* Shop Grid Section */}
        <Box bg="white" py={8}>
          <ScaleFade in={isOpen} initialScale={0.9}>
            <ShopGrid />
          </ScaleFade>
        </Box>

        {/* Programs Section */}
        <Box 
          py={24} 
          bg="gray.50"
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "100px",
            background: "linear-gradient(to bottom, white, transparent)",
          }}
        >
          <Container maxW="container.xl">
            <SlideFade in={isOpen} offsetY="30px">
              <Programs />
            </SlideFade>
          </Container>
        </Box>

        {/* FAQs Section */}
        <Box
          bg="white"
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "100px",
            background: "linear-gradient(to bottom, gray.50, transparent)",
          }}
        >
          <SlideFade in={isOpen} offsetY="30px">
            <Faqs />
          </SlideFade>
        </Box>

        {/* Contact Section */}
        <Box 
          py={20} 
          bg="white"
          w={"full"}
          position="relative"
          _after={{
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "200px",
            bgGradient: "linear(to-t, primary.50, transparent)",
            opacity: 0.5,
          }}
        >
          <SlideFade in={isOpen} offsetY="30px">
            <Contact />
          </SlideFade>
        </Box>
      </Box>
      <Footer links={footerLinks} />
    </>
  );
}