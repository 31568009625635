import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function QrCodeCard({ qrCodeLink }) {
  const { t } = useTranslation();

  return (
    <Box
      bg="white"
      shadow="lg"
      borderRadius="xl"
      p={6}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box flex="0 0 auto">
        <Heading
          as="h3"
          size="md"
          color="primary.800"
          mb={2}
        >
          {t("dashboard.qr.title")}
        </Heading>
        
        <Text
          color="gray.600"
          fontSize="sm"
          mb={6}
        >
          {t("dashboard.qr.description")}
        </Text>
      </Box>

      <Box flex="1 1 auto" display="flex" alignItems="center" justifyContent="center">
        <Image
          src={qrCodeLink}
          alt="QR code"
          maxW={{ base: "200px", md: "220px", lg: "240px" }}
          w="100%"
          h="auto"
          borderRadius="md"
        />
      </Box>
    </Box>
  );
}