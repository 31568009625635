import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Image,
  Flex,
  Stack,
  Icon,
  chakra,
  Stat,
  StatLabel,
  StatNumber,
  Badge,
  Card,
  CardBody,
  Divider,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import {
  IconCheck,
  IconArrowRight,
  IconBuildingStore,
  IconCurrencyEuro,
  IconClock,
  IconAward,
  IconShieldCheck,
  IconUsers,
  IconLock,
  IconServer,
  IconShield,
  IconBuildingSkyscraper,
  IconBuildingCottage,
  IconToolsKitchen2,
  IconTrain,
  IconPlane,
  IconShip,
  IconTicket,
  IconPool,
  IconBuildingCircus,
  IconMapPin,
  IconPercentage,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SeoHelmet from "../../Seo/SeoHelmet";
import Navbar from "../../ReusableComponents/Navbar";
import Footer from "../../ReusableComponents/Footer";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const StatCard = ({ title, stat, icon }) => {
  const bg = useColorModeValue("white", "gray.700");
  
  return (
    <Stat
      px={6}
      py={6}
      bg={bg}
      shadow="md"
      border="1px solid"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      rounded="lg"
      transition="all 0.3s ease"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        h="4px"
        bg="warning.900"
      />
      <Flex justifyContent="space-between" alignItems="center">
        <VStack align="flex-start" spacing={1}>
          <StatLabel
            fontWeight="medium"
            fontSize="md"
            color={useColorModeValue("gray.600", "gray.300")}
          >
            {title}
          </StatLabel>
          <StatNumber
            fontSize="2xl"
            fontWeight="bold"
            color="primary.900"
          >
            {stat}
          </StatNumber>
        </VStack>
        <Flex
          w={12}
          h={12}
          align="center"
          justify="center"
          rounded="full"
          bg="danger.900"
          color="white"
        >
          <Icon as={icon} w={6} h={6} />
        </Flex>
      </Flex>
    </Stat>
  );
};

const Feature = ({ title, text, icon }) => {
  return (
    <Card
      bg={useColorModeValue("white", "gray.700")}
      rounded="lg"
      shadow="md"
      transition="all 0.3s ease"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
      height="100%"
      borderTop="4px solid"
      borderColor="warning.900"
    >
      <CardBody>
        <Flex direction="column" align="center" textAlign="center">
          <Flex
            w={16}
            h={16}
            align="center"
            justify="center"
            color="white"
            rounded="full"
            bg="danger.900"
            mb={4}
          >
            {icon}
          </Flex>
          <chakra.h3 fontSize="xl" fontWeight="bold" color="primary.900" mb={2}>
            {title}
          </chakra.h3>
          <Text color={useColorModeValue("gray.600", "gray.200")}>{text}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
};

const TargetCard = ({ title, icon }) => {
  const bg = useColorModeValue("white", "gray.700");
  
  return (
    <Flex
      bg={bg}
      rounded="lg"
      p={5}
      direction="column"
      align="center"
      justify="center"
      transition="all 0.3s ease"
      _hover={{ transform: "translateY(-5px)", shadow: "md" }}
      border="1px solid"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      shadow="sm"
      height="100%"
    >
      <Icon as={icon} w={8} h={8} color="danger.900" mb={3} />
      <Text
        fontWeight="semibold"
        fontSize="md"
        textAlign="center"
        color={useColorModeValue("gray.700", "white")}
      >
        {title}
      </Text>
    </Flex>
  );
};

const EarningCard = ({ title, earning, commission, icon }) => {
  return (
    <Card 
      shadow="md" 
      borderRadius="lg" 
      overflow="hidden" 
      transition="all 0.3s ease"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
      height="100%"
    >
      <Box bg="danger.900" p={4}>
        <Flex alignItems="center">
          <Icon as={icon} w={8} h={8} color="white" />
          <Heading size="md" ml={3} color="white">
            {title}
          </Heading>
        </Flex>
      </Box>
      <CardBody bg="white">
        <VStack spacing={4} align="stretch">
          <Flex align="center">
            <Icon as={IconCurrencyEuro} color="primary.900" mr={2} />
            <Text fontWeight="bold" fontSize="lg">
              {earning}
            </Text>
          </Flex>
          <Divider />
          <Flex align="center">
            <Icon as={IconPercentage} color="warning.900" mr={2} />
            <Text fontWeight="semibold">
              {commission}
            </Text>
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default function GoldProgramPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "white");

  const targetIcons = {
    shops: IconBuildingStore,
    hotels: IconBuildingSkyscraper,
    restaurants: IconToolsKitchen2,
    stations: IconTrain,
    airports: IconPlane,
    docks: IconShip,
    lunapark: IconBuildingCircus,
    aquapark: IconPool,
    fair: IconTicket
  };

  const footerLinks = [
    {
      label: t("termsConditions.title"),
      link: "/terms-and-conditions",
      className: "iubenda-nostyle no-brand iubenda-embed",
    },
    {
      label: "Privacy Policy",
      link: "https://www.iubenda.com/privacy-policy/",
      className: "iubenda-nostyle no-brand iubenda-embed",
    },
  ];

  return (
    <Box bg={bgColor} minH="100vh">
      <SeoHelmet
        path="/mkt/gold-program"
        title="Closbox Store & Go Gold Program | Host a Locker & Earn"
        description="Join the Closbox Store & Go Gold Program. Host a smart locker in your business, earn passive income, and offer your customers a valuable service with minimal space required."
      />
      <Navbar />

      {/* Hero Section */}
      <Box
        w="full"
        position="relative"
        backgroundImage={`url(${require("../../../Images/Shop1.jpg")})`}
        backgroundSize="cover"
        backgroundPosition={{ base: "left center", md: "center" }}
        display="flex"
        alignItems="center"
        py={{ base: "24", md: "32" }}
      >
        {/* Dark overlay for better text readability */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="blackAlpha.600"
        />
        <Container maxW="container.xl" position="relative" zIndex={1}>
          <VStack
            align={{ base: "center", lg: "flex-start" }}
            spacing={8}
            maxW={{ base: "100%", lg: "60%" }}
            textAlign={{ base: "center", lg: "left" }}
            py={{ base: "8", md: "12" }}
          >
            <Heading
              as="h1"
              fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
              color="white"
              lineHeight={1.2}
              fontWeight="bold"
            >
              {t("marketing.gold.hero.title")}
            </Heading>
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="white"
            >
              {t("marketing.gold.hero.description")}
            </Text>
            <Button
              size="lg"
              colorScheme="red"
              bg="danger.900"
              color="white"
              borderRadius="xl"
              rightIcon={<IconArrowRight />}
              onClick={() => navigate("/locker-onboarding")}
              _hover={{ bg: "danger.700", transform: "translateY(-2px)" }}
            >
              {t("marketing.gold.hero.button")}
            </Button>
          </VStack>
        </Container>
      </Box>

      {/* Earnings Section */}
      <Box py={16} bg={useColorModeValue("white", "gray.800")}>
        <Container maxW="container.xl">
          <VStack spacing={6} mb={12}>
            <Heading
              fontSize={{ base: "2xl", md: "3xl" }}
              textAlign="center"
              color="primary.900"
            >
              {t("marketing.gold.earnings.title")}
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              textAlign="center"
              color={useColorModeValue("gray.600", "gray.300")}
              maxW="3xl"
            >
              {t("marketing.gold.earnings.description")}
            </Text>
          </VStack>
          
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={12}>
            <EarningCard
              title={t("marketing.gold.earnings.highTraffic.title")}
              earning={t("marketing.gold.earnings.highTraffic.payment")}
              commission={t("marketing.gold.earnings.highTraffic.commission")}
              icon={IconMapPin}
            />
            <EarningCard
              title={t("marketing.gold.earnings.regularTraffic.title")}
              earning={t("marketing.gold.earnings.regularTraffic.payment")}
              commission={t("marketing.gold.earnings.regularTraffic.commission")}
              icon={IconMapPin}
            />
          </SimpleGrid>
          
          <Box
            bg="gray.50"
            p={6}
            rounded="lg"
            border="1px dashed"
            borderColor="warning.900"
            textAlign="center"
          >
            <Text fontSize="lg" fontWeight="medium" color="primary.900">
              {t("marketing.gold.earnings.example")}
            </Text>
          </Box>
        </Container>
      </Box>

      {/* Stats Section */}
      <Box py={12}>
        <Container maxW="container.xl">
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
            <StatCard
              title={t("marketing.gold.stats.monthlyEarnings")}
              stat={t("marketing.gold.stats.monthlyEarningsValue")}
              icon={IconCurrencyEuro}
            />
            <StatCard
              title={t("marketing.gold.stats.spaceRequired")}
              stat={t("marketing.gold.stats.spaceRequiredValue")}
              icon={IconBuildingStore}
            />
            <StatCard
              title={t("marketing.gold.stats.customerService")}
              stat={t("marketing.gold.stats.customerServiceValue")}
              icon={IconClock}
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Target Customers Section */}
      <Box py={16} bg={useColorModeValue("white", "gray.800")}>
        <Container maxW="container.xl">
          <VStack spacing={6} mb={10}>
            <Heading
              fontSize={{ base: "2xl", md: "3xl" }}
              textAlign="center"
              color="primary.900"
            >
              {t("marketing.gold.perfectBusiness.title")}
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              textAlign="center"
              color={useColorModeValue("gray.600", "gray.300")}
              maxW="3xl"
            >
              {t("marketing.gold.perfectBusiness.description")}
            </Text>
          </VStack>
          <SimpleGrid
            columns={{ base: 3}}
            spacing={{ base: 3 }}
          >
            {Object.entries(targetIcons).map(([key, icon]) => (
              <TargetCard
                key={key}
                title={t(`marketing.gold.baseCustomers.${key}`)}
                icon={icon}
              />
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box py={16}>
        <Container maxW="container.xl">
          <VStack spacing={8} mb={12}>
            <Heading
              fontSize={{ base: "2xl", md: "3xl" }}
              color="primary.900"
              textAlign="center"
            >
              {t("marketing.gold.whyJoin.title")}
            </Heading>
            <Text fontSize="lg" color="gray.600" maxW="3xl" textAlign="center">
              {t("marketing.gold.whyJoin.description")}
            </Text>
          </VStack>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={8}
          >
            <Feature
              icon={<Icon as={IconCurrencyEuro} w={8} h={8} />}
              title={t("marketing.gold.whyJoin.features.passiveIncome.title")}
              text={t("marketing.gold.whyJoin.features.passiveIncome.description")}
            />
            <Feature
              icon={<Icon as={IconUsers} w={8} h={8} />}
              title={t("marketing.gold.whyJoin.features.newCustomers.title")}
              text={t("marketing.gold.whyJoin.features.newCustomers.description")}
            />
            <Feature
              icon={<Icon as={IconAward} w={8} h={8} />}
              title={t("marketing.gold.whyJoin.features.premiumService.title")}
              text={t("marketing.gold.whyJoin.features.premiumService.description")}
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Benefits Section with Image */}
      <Box py={16} bg={useColorModeValue("white", "gray.800")}>
        <Container maxW="container.xl">
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <VStack
              align="start"
              spacing={6}
              justify="center"
            >
              <Heading color="primary.900" fontSize={{ base: "2xl", md: "3xl" }}>
                {t("marketing.gold.partnership.title")}
              </Heading>
              <Text fontSize="lg" color="gray.600">
                {t("marketing.gold.partnership.description")}
              </Text>
              <List spacing={4}>
                {t("marketing.gold.partnership.benefits", { returnObjects: true }).map((benefit, index) => (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                  >
                    <ListIcon as={IconCheck} color="danger.900" w={5} h={5} />
                    <Text color="gray.700">{benefit}</Text>
                  </ListItem>
                ))}
              </List>
              <Button
                size="lg"
                bg="danger.900"
                color="white"
                rightIcon={<IconArrowRight />}
                onClick={() => navigate("/locker-onboarding")}
                _hover={{ bg: "red.700", transform: "translateY(-2px)" }}
              >
                {t("marketing.gold.partnership.button")}
              </Button>
            </VStack>
            <Box>
              <Box
                position="relative"
                overflow="hidden"
                rounded="lg"
                shadow="xl"
              >
                <Image
                  src={require("../../../Images/Shop_XL.webp")}
                  alt="Closbox Locker in Business"
                  objectFit="cover"
                  w="full"
                  h={{ base: "300px", md: "400px" }}
                  transition="all 0.6s ease"
                  _hover={{ transform: "scale(1.05)" }}
                />
              </Box>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      {/* Security Section */}
      <Box py={16}>
        <Container maxW="container.xl">
          <VStack spacing={6} textAlign="center" mb={10}>
            <Heading fontSize={{ base: "2xl", md: "3xl" }} color="primary.900">
              {t("marketing.gold.security.title")}
            </Heading>
            <Text fontSize="lg" maxW="2xl" color="gray.600">
              {t("marketing.gold.security.description")}
            </Text>
          </VStack>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={8}
          >
            <Card
              bg="white"
              rounded="lg"
              p={6}
              shadow="md"
              _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
              transition="all 0.3s ease"
              height="100%"
            >
              <CardBody>
                <VStack spacing={4} align="center">
                  <Icon as={IconLock} w={10} h={10} color="danger.900" />
                  <Text fontWeight="bold" fontSize="lg" textAlign="center" color="primary.900">
                    {t("marketing.gold.security.features.smartAccess.title")}
                  </Text>
                  <Text
                    color="gray.600"
                    textAlign="center"
                  >
                    {t("marketing.gold.security.features.smartAccess.description")}
                  </Text>
                </VStack>
              </CardBody>
            </Card>
            <Card
              bg="white"
              rounded="lg"
              p={6}
              shadow="md"
              _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
              transition="all 0.3s ease"
              height="100%"
            >
              <CardBody>
                <VStack spacing={4} align="center">
                  <Icon as={IconShield} w={10} h={10} color="danger.900" />
                  <Text fontWeight="bold" fontSize="lg" textAlign="center" color="primary.900">
                    {t("marketing.gold.security.features.insurance.title")}
                  </Text>
                  <VStack
                    color="gray.600"
                    textAlign="center"
                  >
                    <Text>{t("marketing.gold.security.features.insurance.description")}</Text>
                  </VStack>
                </VStack>
              </CardBody>
            </Card>
            <Card
              bg="white"
              rounded="lg"
              p={6}
              shadow="md"
              _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
              transition="all 0.3s ease"
              height="100%"
            >
              <CardBody>
                <VStack spacing={4} align="center">
                  <Icon as={IconServer} w={10} h={10} color="danger.900" />
                  <Text fontWeight="bold" fontSize="lg" textAlign="center" color="primary.900">
                    {t("marketing.gold.security.features.monitoring.title")}
                  </Text>
                  <Text
                    color="gray.600"
                    textAlign="center"
                  >
                    {t("marketing.gold.security.features.monitoring.description")}
                  </Text>
                </VStack>
              </CardBody>
            </Card>
          </SimpleGrid>
        </Container>
      </Box>

      {/* Final CTA Section */}
      <Box py={16}>
        <Container maxW="container.xl">
          <Flex
            direction="column"
            align="center"
            textAlign="center"
            bg="primary.900"
            p={{ base: 8, md: 12 }}
            rounded="lg"
            shadow="xl"
          >
            <Heading color="white" size="xl" mb={4}>
              {t("marketing.gold.cta.title")}
            </Heading>
            <Text color="white" fontSize="lg" opacity={0.9} maxW="2xl" mb={8}>
              {t("marketing.gold.cta.description")}
            </Text>
            <Button
              size="lg"
              bg="warning.900"
              color="white"
              rightIcon={<IconArrowRight />}
              onClick={() => navigate("/locker-onboarding")}
              _hover={{ bg: "orange.600", transform: "translateY(-2px)" }}
              px={8}
            >
              {t("marketing.gold.cta.button")}
            </Button>
          </Flex>
        </Container>
      </Box>

      <Footer links={footerLinks} />
    </Box>
  );
}