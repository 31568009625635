import React from "react";
import {
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Box,
  Container,
  Heading,
  Text,
  useToast,
  FormErrorMessage,
  VStack,
  Flex,
  Progress,
  useColorModeValue,
  Switch,
  SimpleGrid,
  IconButton,
  AspectRatio,
  Center,
  Alert,
  AlertIcon,
  Divider,
  Select,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
  IconChevronLeft, 
  IconChevronRight, 
  IconCheck,
  IconUpload,
  IconX,
  IconPhoto,
  IconPlus
} from "@tabler/icons-react";
import ClosboxV2 from "../../Api/ClosboxV2";
import Navbar from "../ReusableComponents/Navbar";
import SeoHelmet from "../Seo/SeoHelmet";

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export default function LockerOnboarding() {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [step, setStep] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [photos, setPhotos] = React.useState([]);
  const [error, setError] = React.useState("");
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dropzoneBg = useColorModeValue("gray.50", "gray.700");
  const dropzoneHoverBg = useColorModeValue("gray.100", "gray.600");

  const companyType = [
    { value: "bar_pub", label: "Bar or Pub" },
    { value: "restaurant_cafe", label: "Restaurant or Cafe" },
    {
      value: "accommodation_services",
      label: "Accommodation Services (Hotel/B&B/Motel/Vacation Rentals)",
    },
    {
      value: "travel_services",
      label: "Travel Services (Agency, Tour Operator)",
    },
    {
      value: "digital_influencers",
      label: "Digital Influencers (Bloggers, Vloggers, Social Media Personalities)",
    },
    { value: "tour_guide", label: "Tour Guide Services" },
    {
      value: "outdoor_adventures",
      label: "Outdoor Adventures and Recreations",
    },
    { value: "transport_rental", label: "Transport Rental Services" },
    { value: "event_planning", label: "Event Planning and Organizing" },
    {
      value: "educational_services",
      label: "Educational or Workshop Services",
    },
    { value: "visitor_center", label: "Visitor or Information Center" },
    { value: "sports_facility", label: "Sports Facility" },
    { value: "shopping_center", label: "Shopping Center" },
    { value: "service_apartment", label: "Service Apartment" },
    { value: "conference_center", label: "Conference or Convention Center" },
    { value: "Others", label: "Other activities type" },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      companyName: "",
      storeName: "",
      installationAddress: "",
      website: "",
      spaceAvailable: "",
      installationArea: "",
      powerSupply: "",
      additionalInfo: "",
      companyType: "",
      termsAccepted: false,
      privacyAccepted: false,
      marketingAccepted: false,
      ...DAYS.reduce((acc, day) => ({
        ...acc,
        [`${day}Enabled`]: true,
        [`${day}From`]: "09:00",
        [`${day}To`]: "18:00",
      }), {}),
    },
  });

  // Removed convertToBase64 function as we'll use DigitalOceanService instead

  const validatePhotos = () => {
    if (photos.length !== 3) {
      setError(t("lockerOnboarding.validation.photos.required"));
      return false;
    }
    return true;
  };

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => {
      const isValidType = ['image/jpeg', 'image/png', 'image/webp'].includes(file.type);
      const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
      return isValidType && isValidSize;
    });

    if (validFiles.length + photos.length > 3) {
      toast({
        title: "Error",
        description: t("lockerOnboarding.validation.photos.required"),
        status: "error",
        duration: 3000,
      });
      return;
    }

    setPhotos(prev => [...prev, ...validFiles].slice(0, 3));
    setError("");
  };

  const removePhoto = (index) => {
    setPhotos(prev => prev.filter((_, i) => i !== index));
  };

  const previousStep = (e) => {
    e.preventDefault();
    setStep(prev => prev - 1);
  };

  const nextStep = async (e) => {
    e.preventDefault();
    
    let fieldsToValidate;
    switch (step) {
      case 1:
        fieldsToValidate = ['name', 'surname', 'email', 'phone'];
        break;
      case 2:
        fieldsToValidate = [
          'companyType',
          'companyName',
          'installationAddress',
          'spaceAvailable',
          'installationArea',
          'powerSupply'
        ];

        // Validate access hours
        const accessHoursValid = DAYS.some(day => watch(`${day}Enabled`));
        if (!accessHoursValid) {
          toast({
            title: t("lockerOnboarding.validation.accessHours.required"),
            status: "error",
            duration: 3000,
          });
          return;
        }

        // All validations are now handled by inline alerts
        break;
      case 3:
        fieldsToValidate = ['termsAccepted', 'privacyAccepted'];
        if (!watch('termsAccepted') || !watch('privacyAccepted')) {
          toast({
            title: t("lockerOnboarding.validation.required"),
            status: "error",
            duration: 3000,
          });
          return;
        }
        if (!validatePhotos()) {
          return;
        }
        break;
      default:
        return;
    }
    
    const isStepValid = await trigger(fieldsToValidate);
    
    if (isStepValid) {
      setStep(prev => prev + 1);
    } else {
      toast({
        title: t("lockerOnboarding.validation.required"),
        status: "error",
        duration: 3000,
      });
    }
  };

  const formatAccessHours = (values) => {
    return DAYS.map(day => ({
      day,
      enabled: values[`${day}Enabled`],
      from: values[`${day}From`],
      to: values[`${day}To`],
    })).reduce((acc, { day, enabled, from, to }) => {
      acc[day] = enabled ? `${from}-${to}` : 'closed';
      return acc;
    }, {});
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const closboxInstance = ClosboxV2.getInstance();
    let photoUrls = [];
    let ticketResponse;

    try {
      // Try to upload photos first
      try {
        const digitalOceanService = (await import('../../services/DigitalOceanService')).default;
        photoUrls = await digitalOceanService.uploadMultipleImages(photos);
      } catch (uploadError) {
        console.error("Error uploading photos:", uploadError);
        // Continue with submission even if photo upload fails
      }
      
      const accessHours = formatAccessHours(values);

      // Create readable structured message
      const companyTypeLabel = companyType.find(type => type.value === values.companyType)?.label || values.companyType;
      const structuredMessage = `
**Informazioni Azienda**
• Tipo Attività: ${companyTypeLabel}
• Nome Azienda: ${values.companyName}
• Nome Negozio: ${values.storeName || 'Non specificato'}
• Indirizzo Installazione: ${values.installationAddress}
• Sito Web: ${values.website || 'Non specificato'}

**Dettagli Spazio**
• Spazio Disponibile: ${values.spaceAvailable} MQ
• Area Installazione: ${values.installationArea === 'indoor' ? 'Interno' : 'Esterno'}
• Alimentazione Elettrica: ${values.powerSupply === 'yes' ? 'Presente' : 'Non presente'}

**Orari di Accesso**
${Object.entries(accessHours).map(([day, hours]) => {
  const dayTranslated = {
    'monday': 'Lunedì',
    'tuesday': 'Martedì',
    'wednesday': 'Mercoledì',
    'thursday': 'Giovedì',
    'friday': 'Venerdì',
    'saturday': 'Sabato',
    'sunday': 'Domenica'
  }[day];
  return `• ${dayTranslated}: ${hours === 'closed' ? 'Chiuso' : hours}`;
}).join('\n')}

**Informazioni Aggiuntive**
${values.additionalInfo || 'Nessuna informazione aggiuntiva fornita'}

**Foto**
${photoUrls.length > 0 ? photoUrls.map((url, index) => `• Foto ${index + 1}: ${url}`).join('\n') : 'Nessuna foto caricata'}

**Consensi**
• Termini e Condizioni: ${values.termsAccepted ? 'Accettati' : 'Non accettati'}
• Privacy Policy: ${values.privacyAccepted ? 'Accettata' : 'Non accettata'}
• Marketing: ${values.marketingAccepted ? 'Accettato' : 'Non accettato'}`;

      ticketResponse = await closboxInstance.createTicket(
        values.name,
        values.surname,
        values.email,
        values.phone,
        null,
        20,
        "Richiesta Installazione Locker",
        structuredMessage,
        "it"
      );

      // Show appropriate success message based on photo upload status
      if (photoUrls.length === 0) {
        toast({
          title: t("lockerOnboarding.success.title"),
          description: `${t("lockerOnboarding.success.description")} ${t("lockerOnboarding.error.upload")} ${t("lockerOnboarding.error.whatsapp", { number: ticketResponse.number })}`,
          status: "warning",
          duration: 55000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("lockerOnboarding.success.title"),
          description: t("lockerOnboarding.success.description"),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      // Redirect to homepage after 3 seconds
      setTimeout(() => {
        navigate("/");
      }, 3000);

    } catch (error) {
      console.error("Error creating ticket:", error);
      toast({
        title: t("lockerOnboarding.error.title"),
        description: t("lockerOnboarding.error.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderTimeInputs = () => (
    <VStack spacing={4} width="100%">
      {DAYS.map((day) => (
        <Box key={day} width="100%" p={4} borderWidth="1px" borderRadius="lg">
          <VStack spacing={4} align="stretch">
            <Flex justify="space-between" align="center">
              <Text fontWeight="medium">
                {t(`lockerOnboarding.labels.accessHours.days.${day}`)}
              </Text>
              <Controller
                name={`${day}Enabled`}
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    isChecked={field.value}
                    colorScheme="primary"
                    size="lg"
                  />
                )}
              />
            </Flex>
            
            {watch(`${day}Enabled`) && (
              <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
                <FormControl>
                  <FormLabel fontSize="sm">{t("lockerOnboarding.labels.accessHours.from")}</FormLabel>
                  <Input
                    type="time"
                    {...register(`${day}From`)}
                    size="lg"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">{t("lockerOnboarding.labels.accessHours.to")}</FormLabel>
                  <Input
                    type="time"
                    {...register(`${day}To`)}
                    size="lg"
                  />
                </FormControl>
              </SimpleGrid>
            )}
          </VStack>
        </Box>
      ))}
    </VStack>
  );

  const renderPhotoUpload = () => (
    <VStack spacing={6} width="100%">
      <FormControl isInvalid={!!error}>
        <FormLabel>{t("lockerOnboarding.labels.photos.title")}</FormLabel>
        <Text color="gray.600" mb={4}>
          {t("lockerOnboarding.labels.photos.subtitle")}
        </Text>

        <SimpleGrid columns={{ base: 1, sm: photos.length > 0 ? 3 : 1 }} spacing={4} width="100%">
          {photos.map((photo, index) => (
            <Box
              key={index}
              position="relative"
              borderRadius="xl"
              overflow="hidden"
              borderWidth="1px"
              borderColor={borderColor}
            >
              <AspectRatio ratio={4/3}>
                <img
                  src={URL.createObjectURL(photo)}
                  alt={`Location photo ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </AspectRatio>
              <IconButton
                icon={<IconX size={18} />}
                position="absolute"
                top={2}
                right={2}
                size="sm"
                colorScheme="red"
                isRound
                onClick={() => removePhoto(index)}
                aria-label="Remove photo"
              />
            </Box>
          ))}

          {photos.length < 3 && (
            <Center
              borderWidth="2px"
              borderStyle="dashed"
              borderColor="gray.300"
              borderRadius="xl"
              p={8}
              minH="200px"
              bg={dropzoneBg}
              transition="all 0.2s"
              _hover={{
                borderColor: "primary.500",
                bg: dropzoneHoverBg
              }}
              as="label"
              htmlFor="photo-upload"
              cursor="pointer"
              flexDirection="column"
            >
              <Input
                type="file"
                accept="image/jpeg,image/png,image/webp"
                onChange={handlePhotoUpload}
                display="none"
                id="photo-upload"
                multiple
                isDisabled={photos.length >= 3}
              />
              <IconPlus size={48} stroke={1.5} />
              <VStack spacing={2} mt={4}>
                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }} textAlign="center">
                  {t("lockerOnboarding.labels.photos.dropzone")}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {t("lockerOnboarding.labels.photos.maxSize")}
                </Text>
              </VStack>
            </Center>
          )}
        </SimpleGrid>
        
        {error && <FormErrorMessage mt={4}>{error}</FormErrorMessage>}
      </FormControl>

      <VStack spacing={4} width="100%" mt={8}>
        <FormControl isRequired isInvalid={errors.termsAccepted}>
          <Flex align="center">
            <input
              type="checkbox"
              {...register("termsAccepted", { required: true })}
              style={{ marginRight: "8px" }}
            />
            <FormLabel mb={0}>{t("lockerOnboarding.checkboxes.terms")}</FormLabel>
          </Flex>
          {errors.termsAccepted && (
            <FormErrorMessage>
              {t("contactForm.validationErrors.required")}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={errors.privacyAccepted}>
          <Flex align="center">
            <input
              type="checkbox"
              {...register("privacyAccepted", { required: true })}
              style={{ marginRight: "8px" }}
            />
            <FormLabel mb={0}>{t("lockerOnboarding.checkboxes.privacy")}</FormLabel>
          </Flex>
          {errors.privacyAccepted && (
            <FormErrorMessage>
              {t("contactForm.validationErrors.required")}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl>
          <Flex align="center">
            <input
              type="checkbox"
              {...register("marketingAccepted")}
              style={{ marginRight: "8px" }}
            />
            <FormLabel mb={0}>{t("lockerOnboarding.checkboxes.marketing")}</FormLabel>
          </Flex>
        </FormControl>
      </VStack>
    </VStack>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <VStack spacing={6}>
            <FormControl isRequired isInvalid={errors.name}>
              <FormLabel>{t("contactForm.labels.name")}</FormLabel>
              <Input
                {...register("name", { required: true })}
                placeholder={t("contactForm.placeholders.name")}
                size="lg"
              />
              {errors.name && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.surname}>
              <FormLabel>{t("contactForm.labels.surname")}</FormLabel>
              <Input
                {...register("surname", { required: true })}
                placeholder={t("contactForm.placeholders.surname")}
                size="lg"
              />
              {errors.surname && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel>{t("contactForm.labels.email")}</FormLabel>
              <Input
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("contactForm.validationErrors.invalidEmail"),
                  },
                })}
                placeholder={t("contactForm.placeholders.email")}
                size="lg"
              />
              {errors.email && (
                <FormErrorMessage>
                  {errors.email.message || t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.phone}>
              <FormLabel>{t("contactForm.labels.phone")}</FormLabel>
              <Input
                {...register("phone", { required: true })}
                placeholder={t("contactForm.placeholders.phone")}
                size="lg"
              />
              {errors.phone && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>
          </VStack>
        );

      case 2:
        return (
          <VStack spacing={6}>
            <FormControl isRequired isInvalid={errors.companyType}>
              <FormLabel>{t("register.step2.businessType")}</FormLabel>
              <Controller
                name="companyType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select {...field} placeholder={t("register.step2.selectBusiness")} size="lg">
                    {companyType.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
              {errors.companyType && (
                <FormErrorMessage>
                  {t("register.validation.required.business")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.companyName}>
              <FormLabel>{t("lockerOnboarding.labels.companyName")}</FormLabel>
              <Input
                {...register("companyName", { required: true })}
                placeholder={t("lockerOnboarding.placeholders.companyName")}
                size="lg"
              />
              {errors.companyName && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>{t("lockerOnboarding.labels.storeName")}</FormLabel>
              <Input
                {...register("storeName")}
                placeholder={t("lockerOnboarding.placeholders.storeName")}
                size="lg"
              />
            </FormControl>

            <FormControl isRequired isInvalid={errors.installationAddress}>
              <FormLabel>
                {t("lockerOnboarding.labels.installationAddress")}
              </FormLabel>
              <Input
                {...register("installationAddress", { required: true })}
                placeholder={t(
                  "lockerOnboarding.placeholders.installationAddress"
                )}
                size="lg"
              />
              {errors.installationAddress && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired>
              <FormLabel>
                {t("lockerOnboarding.labels.accessHours.title")}
              </FormLabel>
              {renderTimeInputs()}
              {DAYS.some((day) => !watch(`${day}Enabled`)) && (
                <Alert status="warning" mt={2} borderRadius={"lg"}>
                  <AlertIcon />
                  {t("lockerOnboarding.validation.accessHours.closedDay")}
                </Alert>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>{t("lockerOnboarding.labels.website")}</FormLabel>
              <Input
                {...register("website")}
                placeholder={t("lockerOnboarding.placeholders.website")}
                size="lg"
              />
            </FormControl>

            <FormControl isRequired isInvalid={errors.spaceAvailable}>
              <FormLabel>
                {t("lockerOnboarding.labels.spaceAvailable")}
              </FormLabel>
              <Input
                {...register("spaceAvailable", { required: true })}
                placeholder={t("lockerOnboarding.placeholders.spaceAvailable")}
                type="number"
                size="lg"
              />
              {errors.spaceAvailable && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
              {watch("spaceAvailable") &&
                parseFloat(watch("spaceAvailable")) < 5 && (
                  <Alert status="warning" mt={2} borderRadius={"lg"}>
                    <AlertIcon />
                    {t("lockerOnboarding.validation.spaceAvailable.tooSmall")}
                  </Alert>
                )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.installationArea}>
              <FormLabel>
                {t("lockerOnboarding.labels.installationArea")}
              </FormLabel>
              <Controller
                name="installationArea"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column" spacing={4}>
                      <Radio value="indoor" size="lg">
                        {t("lockerOnboarding.options.installationArea.indoor")}
                      </Radio>
                      <Radio value="outdoor" size="lg">
                        {t("lockerOnboarding.options.installationArea.outdoor")}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              {errors.installationArea && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.powerSupply}>
              <FormLabel>{t("lockerOnboarding.labels.powerSupply")}</FormLabel>
              <Controller
                name="powerSupply"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="row" spacing={4}>
                      <Radio value="yes" size="lg">
                        {t("lockerOnboarding.options.powerSupply.yes")}
                      </Radio>
                      <Radio value="no" size="lg">
                        {t("lockerOnboarding.options.powerSupply.no")}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              {errors.powerSupply && (
                <FormErrorMessage>
                  {t("contactForm.validationErrors.required")}
                </FormErrorMessage>
              )}
              {watch("powerSupply") === "no" && (
                <Alert status="warning" mt={2} borderRadius={"lg"}>
                  <AlertIcon />
                  {t("lockerOnboarding.validation.powerSupply.required")}
                </Alert>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>
                {t("lockerOnboarding.labels.additionalInfo")}
              </FormLabel>
              <Textarea
                {...register("additionalInfo")}
                placeholder={t("lockerOnboarding.placeholders.additionalInfo")}
                size="lg"
                rows={4}
              />
            </FormControl>
          </VStack>
        );

      case 3:
        return renderPhotoUpload();

      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />

      <Box minH="100vh" py={10} px={4}>
        <Container maxW="container.md">
          <Box mt={14} marginBottom={5}>
            <Heading
              color="primary.800"
              fontSize={{ base: "2xl", md: "4xl" }}
              mb={4}
            >
              {t("lockerOnboarding.title")}
            </Heading>

            <Text color="gray.600" fontSize="md" marginBottom={8}>
              {t("lockerOnboarding.subtitle")}
            </Text>
            <Divider my="8" />

            <Text color="primary.900" mb={1} textTransform="uppercase" size={"sm"} fontWeight={"bold"}>
              Step {step}/3
            </Text>
            <Progress
              value={(step / 3) * 100}
              size="sm"
              colorScheme="primary"
              borderRadius="full"
              mb={8}
            />

            <Box bg={bgColor}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {renderStepContent()}

                <Flex
                  width="100%"
                  justify="flex-end"
                  gap={4}
                  direction={{ base: "column", sm: "row" }}
                  mt={8}
                >
                  {step > 1 && (
                    <Button
                      variant="outline"
                      colorScheme="primary"
                      size="lg"
                      onClick={previousStep}
                      leftIcon={<IconChevronLeft size={20} />}
                      width={{ base: "100%", sm: "auto" }}
                    >
                      {t("lockerOnboarding.buttons.previous")}
                    </Button>
                  )}

                  {step < 3 ? (
                    <Button
                      colorScheme="primary"
                      size="lg"
                      onClick={nextStep}
                      rightIcon={<IconChevronRight size={20} />}
                      width={{ base: "100%", sm: "auto" }}
                    >
                      {t("lockerOnboarding.buttons.next")}
                    </Button>
                  ) : (
                    <Button
                      colorScheme="primary"
                      size="lg"
                      type="submit"
                      isLoading={isLoading}
                      rightIcon={<IconCheck size={20} />}
                      width={{ base: "100%", sm: "auto" }}
                    >
                      {t("lockerOnboarding.buttons.submit")}
                    </Button>
                  )}
                </Flex>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}