import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function Faqs() {
  const { t } = useTranslation();

  return (
    <Box as="section" py={20} bg="danger.50">
      <Container maxW="container.xl">
        <Box textAlign="center" mb={12}>
          <Text
            textTransform="uppercase"
            letterSpacing="wide"
            fontWeight="bold"
            fontSize="sm"
            color="primary.900"
            mb={3}
          >
            {t("homepage.faqs.badge", "FAQ")}
          </Text>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="bold"
            color="primary.900"
          >
            {t("homepage.faqs.title", "Frequently Asked Questions")}
          </Heading>
        </Box>

        <Accordion allowMultiple maxW="3xl" mx="auto">
          {[...Array(10)].map((_, index) => (
            <AccordionItem
              key={index}
              border="1px"
              borderColor="gray.200"
              bg="white"
              rounded="lg"
              mb={4}
            >
              <h3>
                <AccordionButton
                  py={4}
                  px={6}
                  _hover={{ bg: "gray.50" }}
                  _expanded={{ bg: "primary.50" }}
                >
                  <Box flex="1" textAlign="left" fontWeight="semibold" color="primary.900">
                    {t(`homepage.faqs.q${index + 1}`)}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel py={6} px={6}>
                {t(`homepage.faqs.a${index + 1}`)}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Box>
  );
}