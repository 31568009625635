import React from "react";
import {
  Container,
  HStack,
  Link,
  Text,
  Box,
  Stack,
  Flex,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import ClosboxLogo from "./ClosboxLogo";

const loadScript = (src) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
};

export default function Footer({ links }) {
  React.useEffect(() => {
    loadScript("https://cdn.iubenda.com/iubenda.js");
  }, []);

  const currentYear = new Date().getFullYear();

  const items = links.map((link) => (
    <WrapItem key={link.label}>
      <Link
        href={link.link}
        className={link.className}
        fontSize={{ base: "xs", sm: "sm" }}
        color="gray.600"
        _hover={{ color: "gray.800" }}
        px={{ base: 2, sm: 3 }}
      >
        {link.label}
      </Link>
    </WrapItem>
  ));

  return (
    <Box as="footer" width="100%" borderTop="1px" borderColor="gray.100">
      <Container
        maxW="container.xl"
        py={{ base: 4, sm: 6, md: 8 }}
        px={{ base: 4, sm: 6, md: 8 }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align={{ base: "center", md: "center" }}
          spacing={{ base: 4, md: 0 }}
          w="100%"
        >
          <Box mb={{ base: 2, md: 0 }}>
            <ClosboxLogo />
          </Box>

          <Flex
            justify="center"
            align="center"
            textAlign={{ base: "center", md: "left" }}
            w={{ base: "100%", md: "auto" }}
          >
            <Wrap
              spacing={{ base: 2, sm: 3 }}
              justify={{ base: "center", md: "flex-start" }}
              align="center"
            >
              {items}
            </Wrap>
          </Flex>

          <Text
            color="primary.800"
            fontSize={{ base: "xs", sm: "sm" }}
            textAlign={{ base: "center", md: "right" }}
            mt={{ base: 2, md: 0 }}
          >
            All rights reserved Closbox.com © {currentYear}
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}
