import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  SimpleGrid,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { IconShare } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function ShareSocialModal({ link }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const shareButtons = [
    {
      Button: WhatsappShareButton,
      Icon: WhatsappIcon,
      text: t("share.modal.buttons.whatsapp"),
      props: {
        url: link,
        title: t("share.modal.content.description"),
      },
    },
    {
      Button: FacebookShareButton,
      Icon: FacebookIcon,
      text: t("share.modal.buttons.facebook"),
      props: {
        url: link,
        quote: t("share.modal.content.description"),
        hashtag: t("share.modal.content.hashtag"),
      },
    },
    {
      Button: LinkedinShareButton,
      Icon: LinkedinIcon,
      text: t("share.modal.buttons.linkedin"),
      props: {
        url: link,
        title: t("share.modal.content.title"),
        summary: t("share.modal.content.description"),
        source: t("share.modal.content.source"),
      },
    },
    {
      Button: TwitterShareButton,
      Icon: TwitterIcon,
      text: t("share.modal.buttons.twitter"),
      props: {
        url: link,
        title: t("share.modal.content.title"),
        via: t("share.modal.content.source"),
      },
    },
  ];

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="xl">
          <ModalHeader color="primary.800">
            {t("share.modal.title")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={1} spacing={4}>
              {shareButtons.map(({ Button: ShareButton, Icon, text, props }) => (
                <ShareButton key={text} {...props}>
                  <Button
                    width="100%"
                    variant="outline"
                    colorScheme="primary"
                    borderRadius="full"
                    size="lg"
                    rightIcon={<Icon size={20} round />}
                  >
                    {text}
                  </Button>
                </ShareButton>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex justify="flex-end">
        <Button
          onClick={onOpen}
          colorScheme="primary"
          borderRadius="full"
          rightIcon={<IconShare />}
          size="lg"
        >
          {t("share.modal.shareButton")}
        </Button>
      </Flex>
    </>
  );
}
