import React from "react";
import {
  Box,
  Container,
  Badge,
  Heading,
  Card,
  CardBody,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ContactForm from "../ReusableComponents/ContactForm";

export default function Contacts() {
  const { t } = useTranslation();

  return (
    <Box
      w="100%"
    >
      {/* Sfondo con gradient e overlay */}
      <Box
        position="absolute"
        inset="0"
        bgGradient="linear(to-br, danger.700, danger.900)"
        opacity="0.85"
        zIndex={0}
      />
      {/* Contenuto */}
      <Container maxW="container.xl" position="relative" zIndex={1} p={4}>
        <VStack spacing={8} align="start" mb={12}>
          <Badge
            bg="white"
            color="danger.600"
            px={6}
            py={2}
            borderRadius="full"
            fontSize="md"
            textTransform="uppercase"
            letterSpacing="wider"
            boxShadow="lg"
            transition="transform 0.3s ease"
            _hover={{ transform: "translateY(-2px)" }}
          >
            {t("homepage.contacts.badge")}
          </Badge>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="extrabold"
            color="white"
            bgGradient="linear(to-r, white, whiteAlpha.800)"
            bgClip="text"
            letterSpacing="tight"
            lineHeight="shorter"
          >
            {t("homepage.contacts.title")}
          </Heading>
          <Text
            color="whiteAlpha.900"
            fontSize={{ base: "lg", md: "xl" }}
            maxW="600px"
            position="relative"
            _after={{
              content: '""',
              display: "block",
              width: "60px",
              height: "3px",
              bg: "white",
              borderRadius: "full",
              mt: 4,
              opacity: 0.3,
            }}
          >
            {t(
              "homepage.contacts.description",
              "Hai domande sul programma di affiliazione? Compila il form sottostante e ti ricontatteremo entro 24 ore."
            )}
          </Text>
        </VStack>
        <Card
          borderRadius="2xl"
          boxShadow="2xl"
          bg={useColorModeValue("whiteAlpha.900", "gray.800")}
          overflow="hidden"
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            bgGradient: "linear(to-r, danger.500, danger.300)",
          }}
        >
          <CardBody p={{ base: 6, md: 10 }} position="relative">
            {/* Qui abbiamo integrato il nuovo ContactForm con validazione */}
            <ContactForm />
          </CardBody>
        </Card>
      </Container>
      {/* Elementi decorativi in basso */}
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        height="200px"
        bgGradient="linear(to-t, blackAlpha.400, transparent)"
        pointerEvents="none"
      />
    </Box>
  );
}
