import React from "react";
import {
  Box,
  Container,
  Flex,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import ClosboxLogo from "./ClosboxLogo";
import { IconArrowsRightLeft, IconUser } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import { LanguageSwitcher } from "../../Localization";

const HEADER_HEIGHT = "60px";

export default function Navbar() {
  const navigate = useNavigate();
  const { isAuthenticated } = useUser();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const toLoginPage = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  return (
    <Box
      as="header"
      position="fixed"
      w="100%"
      height={HEADER_HEIGHT}
      bg={bgColor}
      borderBottom="1px"
      borderColor={borderColor}
      zIndex="sticky"
      top={0}
    >
      <Container maxW="container.lg" height="100%">
        <Flex
          height="100%"
          align="center"
          justify="space-between"
        >
          <Box
            cursor="pointer"
            onClick={() => navigate("/dashboard")}
          >
            <ClosboxLogo />
          </Box>

          <Flex align="center" gap={4}>
            <LanguageSwitcher />
            
            {!isAuthenticated ? (
              <Button
                colorScheme="primary"
                rounded="full"
                onClick={toLoginPage}
              >
                Log In
              </Button>
            ) : (
              <Button
                variant="outline"
                colorScheme="danger"
                rounded="full"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </Button>
            )}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
