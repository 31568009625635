import React from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  Tooltip,
  Flex,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IconCheck, IconCopy } from "@tabler/icons-react";

export default function CouponCard({ couponCode }) {
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(couponCode);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    toast({
      description: t("dashboard.coupon.copy.success"),
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box
      shadow="lg"
      borderRadius="xl"
      p={6}
      bg="white"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Heading
        as="h3"
        size="md"
        color="primary.800"
        mb={2}
      >
        {t("dashboard.coupon.title")}
      </Heading>
      
      <Text color="gray.600" fontSize="sm">
        {t("dashboard.coupon.description")}
      </Text>

      <Flex
        minH="50px"
        bg="gray.50"
        gap={4}
        borderRadius="xl"
        justify="center"
        align="center"
        my={5}
        p={4}
      >
        <Text color="gray.600">{couponCode}</Text>
        <Tooltip
          label={t(hasCopied ? "dashboard.coupon.copy.tooltipCopied" : "dashboard.coupon.copy.tooltip")}
          hasArrow
          placement="right"
        >
          <IconButton
            aria-label={t(hasCopied ? "dashboard.coupon.copy.tooltipCopied" : "dashboard.coupon.copy.tooltip")}
            icon={hasCopied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
            size="sm"
            variant="ghost"
            colorScheme={hasCopied ? "green" : "gray"}
            onClick={handleCopy}
          />
        </Tooltip>
      </Flex>
    </Box>
  );
}
