import React from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  Link,
  Alert,
  AlertIcon,
  Box,
  Container,
  VStack,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Closbox from "../../Api/Closbox";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import { IconExclamationCircle } from "@tabler/icons-react";
import Navbar from "../ReusableComponents/Navbar";
import { useTranslation } from "react-i18next";

export default function LoginForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const { setUserData } = useUser();
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation();

  function login(event) {
    setError(null);
    setIsLoading(true);
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const loginForm = Object.fromEntries(formData.entries());

    const closboxApi = Closbox.getInstance();

    closboxApi
      .login(loginForm.email, loginForm.password, "affiliates")
      .then((data) => {
        if (data.token != null && data.user != null) {
          closboxApi.setAuthToken(data.token);
          setUserData({ user: data.user, token: data.token });
          navigate("/dashboard");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setError(t("login.errors.invalidCredentials"));
        setIsLoading(false);
      });
  }

  const toSignupPage = (event) => {
    event.preventDefault();
    navigate("/signup");
  };

  return (
    <>
      <Navbar />
      <Box
        minH="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={6}
      >
        <Container maxW="md">
          <VStack spacing={6} align="center" w="100%">
            <Heading
              as="h1"
              fontSize={{ base: "3xl", md: "5xl" }}
              color="primary.800"
            >
              {t("login.title")}
            </Heading>

            <Text color="gray.600" fontSize="lg">
              {t("login.subtitle")}{" "}
              <Link
                color="primary.800"
                onClick={toSignupPage}
                _hover={{ textDecoration: "underline" }}
              >
                {t("login.signup")}
              </Link>
            </Text>

            <Box w="100%">
              <form onSubmit={login}>
                <VStack spacing={4} w="100%">
                  <FormControl isRequired isInvalid={error}>
                    <FormLabel>{t("login.form.email")}</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      placeholder={t("login.form.emailPlaceholder")}
                      size="lg"
                      borderRadius="md"
                    />
                  </FormControl>

                  <FormControl isRequired isInvalid={error}>
                    <FormLabel>{t("login.form.password")}</FormLabel>
                    <InputGroup size="lg">
                      <Input
                        name="password"
                        type={isOpen ? "text" : "password"}
                        placeholder={t("login.form.passwordPlaceholder")}
                        borderRadius="md"
                      />
                      <InputRightElement>
                        <IconButton
                          variant="ghost"
                          onClick={onToggle}
                          icon={isOpen ? <ViewOffIcon /> : <ViewIcon />}
                          aria-label={t(
                            isOpen
                              ? "login.form.hidePassword"
                              : "login.form.showPassword"
                          )}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  {error && (
                    <Alert status="error" borderRadius="md">
                      <AlertIcon />
                      <Text color="red.800">{error}</Text>
                    </Alert>
                  )}

                  <Button
                    type="submit"
                    colorScheme="primary"
                    size="lg"
                    width="100%"
                    borderRadius="full"
                    isLoading={isLoading}
                    mt={4}
                  >
                    {t("login.form.submit")}
                  </Button>
                </VStack>
              </form>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  );
}
