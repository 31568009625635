import React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import { parseISO, formatDistanceToNow } from "date-fns";
import noEarnings from "../../Images/Illustrations/no-earnings.svg";
import PayoutRequestModal from "../Billing/PayoutRequestModal";
import { IconAlertCircle } from "@tabler/icons-react";
import {
  Box,
  Container,
  Heading,
  Text,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Grid,
  GridItem,
  Image,
  Card,
  CardBody,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Avatar,
  Tooltip,
  Spinner,
  Center,
  HStack,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function Billing() {
  const { userData, setUserData } = useUser();
  const [affiliationData, setAffiliationData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const savedData = sessionStorage.getItem("affiliationData");
  const { t } = useTranslation();

  const fetchAffiliateData = React.useCallback(() => {
    const closboxApi = Closbox.getInstance();
    closboxApi.setAuthToken(userData.token);

    closboxApi
      .syncAffiliate(userData.user.id)
      .then((response) => {
        const data = response.data;
        setUserData({
          user: userData.user,
          token: userData.token,
          company: data.company,
        });
        sessionStorage.setItem("affiliationData", JSON.stringify(data));
        setAffiliationData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        if (!savedData) {
          setIsLoading(false);
        }
      });
  }, [userData.token, userData.user.id, setUserData, savedData]);

  React.useEffect(() => {
    if (savedData) {
      setAffiliationData(JSON.parse(savedData));
    }
    fetchAffiliateData();
  }, [savedData, fetchAffiliateData]);

  React.useEffect(() => {
    const totalRecords = affiliationData
      ? affiliationData.allCommissionRecords.length
      : 0;
    setTotalPages(Math.ceil(totalRecords / ITEMS_PER_PAGE));
  }, [affiliationData]);

  let unpaidRecords = [];
  let data = null;
  let totalAmountDue = 0;

  if (affiliationData) {
    data = affiliationData.allCommissionRecords;
    data.forEach((record) => {
      if (record.status === "confirmed") {
        totalAmountDue += record.commission_amount;
        unpaidRecords.push(record);
      }
    });
  }

  const statusColors = {
    created: "primary",
    confirmed: "green",
    cancelled: "red",
    pending: "gray",
  };

  if (isLoading || !data) {
    return (
      <Container h="100vh">
        <Center h="100%">
          <Spinner size="xl" color="danger.800" />
        </Center>
      </Container>
    );
  }

  // Calculate which records to display for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentRecords = affiliationData
    ? affiliationData.allPayoutRequests.slice(startIndex, endIndex)
    : [];

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const rows = currentRecords.map((record) => (
    <Tr key={record.booking_id}>
      <Td>
        <HStack spacing={4}>
          <Avatar size="sm" name="🚀" />
          <Box>
            <Tooltip label={record.description}>
              <Text
                color="primary.800"
                fontWeight="500"
                noOfLines={1}
                maxW="300px"
              >
                {record.description}
              </Text>
            </Tooltip>
            <Text fontSize="sm" color="gray.600">
              {t("dashboard.billing.history.table.createdAt", {
                time: formatDistanceToNow(parseISO(record.created_at)),
              })}
            </Text>
          </Box>
        </HStack>
      </Td>

      <Td>
        <Badge
          colorScheme={statusColors[record.status.toLowerCase()]}
          variant="outline"
        >
          {record.status}
        </Badge>
      </Td>

      <Td>
        <Text color="primary.800" fontSize="sm">
          {t("dashboard.billing.history.table.totalAmount")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          {formatAmount(record.amount)}
        </Text>
      </Td>

      <Td>
        <Text color="primary.800" fontSize="sm">
          {t("dashboard.billing.history.table.payoutId")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          {record.u_payout_id}
        </Text>
      </Td>

      <Td>
        <Text color="primary.800" fontSize="sm">
          {t("dashboard.billing.history.table.paidOn")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          {record.paid_on === null
            ? t("dashboard.billing.history.table.notPaidYet")
            : record.paid_on}
        </Text>
      </Td>
    </Tr>
  ));

  return (
    <UserDashboardWrapper>
      <Container maxW="container.xl">
        {userData.company.iban === null && (
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            borderRadius="xl"
            mb={6}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t("dashboard.billing.noIban.title")}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {t("dashboard.billing.noIban.description")}
            </AlertDescription>
          </Alert>
        )}

        <Heading as="h1" size="xl" color="primary.800" mb={6}>
          {t("dashboard.billing.title")}
        </Heading>

        {unpaidRecords.length > 0 ? (
          <Box bg="white" borderRadius="xl" p={6} shadow="md" mb={6}>
            <Heading as="h3" size="lg" color="primary.800" mb={4}>
              {t("dashboard.billing.payoutRequest.title", {
                amount: formatAmount(totalAmountDue),
              })}
            </Heading>
            <Text color="gray.600" mb={6}>
              {t("dashboard.billing.payoutRequest.description", {
                amount: formatAmount(totalAmountDue),
              })}
            </Text>
            <PayoutRequestModal
              data={affiliationData}
              onSuccessfulPayout={fetchAffiliateData}
            />
          </Box>
        ) : (
          <Card borderRadius="xl" mb={6}>
            <CardBody>
              <Grid templateColumns={{ base: "1fr", lg: "1fr 2fr" }} gap={6}>
                <GridItem>
                  <Image
                    src={noEarnings}
                    alt="No Earnings"
                    maxW="250px"
                    mx="auto"
                  />
                </GridItem>
                <GridItem>
                  <Center h="100%">
                    <Box>
                      <Heading as="h2" size="lg" color="primary.800" mb={4}>
                        {t("dashboard.billing.noEarnings.title")}
                      </Heading>
                      <Text color="gray.600">
                        {t("dashboard.billing.noEarnings.description")}
                      </Text>
                    </Box>
                  </Center>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}

        {currentRecords.length > 0 && (
          <>
            <Heading as="h3" size="lg" color="primary.800" mb={6}>
              {t("dashboard.billing.history.title")}
            </Heading>

            <Box
              overflowX="auto"
              bg="white"
              borderRadius="xl"
              shadow="md"
              mb={6}
            >
              <Table variant="simple" minW="800px">
                <Tbody>{rows}</Tbody>
              </Table>
            </Box>

            <Flex justify="center" mt={6}>
              <ButtonGroup>
                {[...Array(totalPages)].map((_, i) => (
                  <Button
                    key={i + 1}
                    onClick={() => setCurrentPage(i + 1)}
                    colorScheme={currentPage === i + 1 ? "danger" : "gray"}
                    variant={currentPage === i + 1 ? "solid" : "outline"}
                  >
                    {i + 1}
                  </Button>
                ))}
              </ButtonGroup>
            </Flex>
          </>
        )}
      </Container>
    </UserDashboardWrapper>
  );
}
