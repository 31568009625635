import React from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Icon,
  VStack,
  HStack,
} from "@chakra-ui/react";
import {
  IconQrcode,
  IconCoinEuro,
  IconUserDollar,
  IconCoin,
  IconArrowUpRight,
  IconArrowDownRight,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const icons = {
  qr: IconQrcode,
  earnings: IconCoinEuro,
  booking: IconUserDollar,
  coin: IconCoin,
};

function StatsGrid({ data }) {
  const { t } = useTranslation();

  const getStatTitle = (icon) => {
    switch (icon) {
      case 'qr':
        return t('dashboard.stats.monthlyScans');
      case 'earnings':
        return t('dashboard.stats.monthlyEarnings');
      case 'booking':
        return t('dashboard.stats.bookings');
      default:
        return '';
    }
  };

  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Box
        key={stat.icon}
        bg="white"
        p={6}
        borderRadius="xl"
        boxShadow="sm"
      >
        <Flex justify="space-between" align="center">
          <Text
            fontSize="xs"
            color="gray.600"
            fontWeight="700"
            textTransform="uppercase"
          >
            {getStatTitle(stat.icon)}
          </Text>
          <Icon
            as={Icon}
            boxSize="1.4rem"
            color="gray.400"
            strokeWidth={1.5}
          />
        </Flex>

        <HStack spacing={2} mt={6} align="flex-end">
          <Text
            fontSize="2xl"
            fontWeight="700"
            lineHeight="1"
            color="primary.800"
          >
            {stat.value}
          </Text>
          {stat.diff !== "" && (
            <Flex
              align="center"
              color={stat.diff > 0 ? "green.500" : (stat.diff === "" ? "primary.800" : "red.500")}
              fontSize="sm"
              fontWeight="500"
            >
              <Text>{stat.diff}%</Text>
              <Icon
                as={DiffIcon}
                boxSize="1rem"
                strokeWidth={1.5}
                ml={1}
              />
            </Flex>
          )}
        </HStack>

        <Text
          fontSize="xs"
          color="gray.600"
          mt={2}
        >
          {t('dashboard.stats.comparison')}
        </Text>
      </Box>
    );
  });

  return (
    <Box py={{ base: 4, md: 6 }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={{ base: 4, md: 6, lg: 8 }}
        alignItems="stretch"
      >
        {stats}
      </SimpleGrid>
    </Box>
  );
}

export default StatsGrid;
