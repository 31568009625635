import React from "react";
import {
  Container,
  Text,
  Heading,
  UnorderedList,
  OrderedList,
  ListItem,
  Link,
  Box,
  Spinner,
  Center,
} from "@chakra-ui/react";
import Closbox from "../../Api/Closbox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Navbar from "../ReusableComponents/Navbar";

function parseHtmlToChakra(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const parseNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue;
    }

    let children = Array.from(node.childNodes).map(parseNode);

    switch (node.nodeName) {
      case "H1":
        return (
          <Heading as="h1" size="xl" my={6}>
            {children}
          </Heading>
        );
      case "H2":
        return (
          <Heading as="h2" size="lg" my={5}>
            {children}
          </Heading>
        );
      case "H3":
        return (
          <Heading as="h3" size="md" my={4}>
            {children}
          </Heading>
        );
      case "H4":
        return (
          <Heading as="h4" size="sm" my={3}>
            {children}
          </Heading>
        );
      case "H5":
      case "H6":
        return (
          <Heading as="h5" size="xs" my={2}>
            {children}
          </Heading>
        );
      case "P":
        return <Text my={4}>{children}</Text>;
      case "OL":
        return <OrderedList my={4}>{children}</OrderedList>;
      case "UL":
        return <UnorderedList my={4}>{children}</UnorderedList>;
      case "LI":
        return <ListItem>{children}</ListItem>;
      case "A":
        return (
          <Link
            href={node.getAttribute("href")}
            isExternal
            color="primary.800"
            textDecoration="underline"
          >
            {children}
          </Link>
        );
      default:
        return <Box>{children}</Box>;
    }
  };

  return parseNode(doc.body);
}

const TermsAndConditionsScreen = () => {
  const closbox = Closbox.getInstance();
  const [body, setBody] = React.useState("");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsLoading(true);
    closbox
      .getPolicy(2)
      .then((res) => {
        if (res.body) {
          setBody(res.body.it);
        }
      })
      .catch((e) => {
        console.error(e);
        navigate("/");
      })
      .finally(() => setIsLoading(false));
  }, [closbox, navigate]);

  return (
    <Container maxW="container.lg" mt={8}>
      <Navbar />
      <Box my={6}>
        <Heading as="h1" size="xl" color="primary.800">
          {t("termsConditions.title")}
        </Heading>
        {isLoading ? (
          <Center h="60vh">
            <Spinner size="xl" color="primary.800" />
          </Center>
        ) : (
          <Box>{parseHtmlToChakra(body)}</Box>
        )}
      </Box>
    </Container>
  );
};

export { TermsAndConditionsScreen };
