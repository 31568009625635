import React from "react";
import {
  Box,
  Container,
  Heading,
  Button,
  Text,
  Flex,
  Stack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import heroImage from "../../Images/hero.webp";
import { IconArrowDown } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// Componente per evidenziare porzioni del titolo (continua sulla stessa riga)
const ColoredText = ({ children }) => (
  <Text as="h1" display="inline" color="danger.900" fontWeight="bold">
    {children}
  </Text>
);

// Componente per il tag delle caratteristiche
const FeatureTag = ({ children }) => (
  <Text
    as="span"
    fontWeight="bold"
    color="danger.900"
    bg="danger.100"
    px={2}
    py={1}
    borderRadius="md"
    mr={1}
  >
    {children}
  </Text>
);

export default function HeroBanner() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  // Funzione per scrollare alla sezione "programs"
  const scrollToProgram = () => {
    const section = document.getElementById("programs");
    if (section) section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      as="section"
      minH="auto"
      position="relative"
    >
      <Container maxW="container.xl" position="relative" zIndex={1} py={10}>
        <Flex
          direction={{ base: "column", lg: "row" }}
          align="center"
          justify="space-between"
        >
          {/* Sezione centrale con titolo, sottotitolo e CTA */}
          <Stack
            spacing={6}
            flex="1"
            textAlign={{ base: "center", lg: "left" }}
          >
            <Text
              textTransform="uppercase"
              letterSpacing="wide"
              fontWeight="bold"
              fontSize="sm"
              color="primary.900"
            >
              {t(
                "homepage.heroBanner.program-label",
                "LUGGAGE STORAGE PARTNER PROGRAM"
              )}
            </Text>
            <Heading
              as="h1"
              fontSize={{ base: "4xl", md: "6xl" }}
              fontWeight="extrabold"
              lineHeight="1.2"
              color="primary.900"
            >
              <Trans
                i18nKey="homepage.heroBanner.title"
                components={{ colored: <ColoredText /> }}
              />
            </Heading>
            <Text fontSize={{ base: "lg", md: "xl" }} color="gray.400">
              {t(
                "homepage.heroBanner.subtitle",
                "Scopri come il nostro programma può trasformare il tuo business nel settore del deposito bagagli."
              )}
            </Text>
            {/* CTA: da mobile vengono mostrate in colonna, da desktop in riga */}
            <Stack
              spacing={4}
              direction={{ base: "column", lg: "row" }}
              justify={{ base: "center", lg: "flex-start" }}
            >
              <Button
                size="lg"
                bg="danger.900"
                color="white"
                rounded="full"
                px={10}
                py={6}
                fontWeight="bold"
                _hover={{ bg: "danger.700", transform: "translateY(-2px)" }}
                onClick={() => navigate("/signup")}
              >
                {t("homepage.heroBanner.btn-1", "Iscriviti Ora")}
              </Button>
              <Button
                size="lg"
                variant="outline"
                borderColor="primary.900"
                color="primary.900"
                rounded="full"
                px={10}
                py={6}
                fontWeight="bold"
                rightIcon={<IconArrowDown size={20} />}
                _hover={{ borderColor: "primary.900", transform: "translateY(-2px)" }}
                onClick={scrollToProgram}
              >
                {t("homepage.heroBanner.btn-2", "Scopri di più")}
              </Button>
            </Stack>
          </Stack>

          {/* Hero Image: visibile solo su dispositivi desktop */}
          {!isMobile && (
            <Box flex="1" maxW="500px" ml={10}>
              <Image
                src={heroImage}
                alt="Closbox Hero"
                borderRadius="xl"
                boxShadow="lg"
                w="full"
                h="auto"
                objectFit="cover"
              />
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  );
}
