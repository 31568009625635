import React from "react";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import { Image, Box } from "@chakra-ui/react";

function CountryFlag({ countryCode }) {
  const svgString = CountryFlagSvg[countryCode];
  const svgDataUri = `data:image/svg+xml,${encodeURIComponent(svgString)}`;

  return (
    <Box
      borderRadius="full"
      width="25px"
      height="25px"
      overflow="hidden"
    >
      <Image
        src={svgDataUri}
        alt={`Flag of ${countryCode}`}
        width="100%"
        height="100%"
        objectFit="cover"
        objectPosition="center"
      />
    </Box>
  );
}

export default CountryFlag;
