import React from "react";
import {
  Box,
  Flex,
  VStack,
  Icon,
  Text,
  Badge,
  IconButton,
  useDisclosure,
  Link,
  Container,
  useBreakpointValue,
  HStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import ClosboxLogo from "./ClosboxLogo";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import { LanguageSwitcher } from "../../Localization";
import {
  IconBellRinging,
  IconFingerprint,
  IconSettings,
  IconReceipt2,
  IconLogout,
  IconUser,
} from "@tabler/icons-react";

const data = [
  { link: "/dashboard", label: "Dashboard", icon: IconBellRinging },
  { link: "/billing", label: "Billing", icon: IconReceipt2 },
  { link: "/bookings", label: "Bookings", icon: IconFingerprint },
  { link: "/account", label: "Account", icon: IconSettings },
];

export default function Wrapper({ children }) {
  let affiliationData = sessionStorage.getItem("affiliationData");
  if (affiliationData) {
    affiliationData = JSON.parse(affiliationData);
  }

  const location = useLocation();
  const { userData, logout } = useUser();
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  const activeLink = data.find((item) => item.link === location.pathname);
  const [active, setActive] = React.useState(
    activeLink ? activeLink.label : "Dashboard"
  );

  const showSidebar = useBreakpointValue({ base: isOpen, md: true });

  const toHomePage = (event) => {
    navigate("/");
  };

  const NavLink = ({ item }) => (
    <Link
      w="100%"
      display="flex"
      alignItems="center"
      p={3}
      borderRadius="md"
      textDecoration="none"
      color={item.label === active ? "primary.800" : "gray.600"}
      bg={item.label === active ? "primary.50" : "transparent"}
      _hover={{
        bg: "primary.50",
        color: "primary.800",
      }}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        navigate(item.link);
      }}
    >
      <Icon as={item.icon} mr={3} />
      <Text>{item.label}</Text>
    </Link>
  );

  return (
    <Box minH="100vh">
      {/* Fixed Header */}
      <Flex
        as="header"
        bg="white"
        h="70px"
        px={6}
        align="center"
        justify="space-between"
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={30}
      >
        <HStack spacing={4}>
          <IconButton
            display={{ base: "flex", md: "none" }}
            onClick={onToggle}
            variant="ghost"
            icon={<HamburgerIcon />}
            aria-label="Toggle Navigation"
          />
          <Box cursor="pointer" onClick={toHomePage}>
            <ClosboxLogo />
          </Box>
        </HStack>
        <LanguageSwitcher />
      </Flex>

      {/* Main Content Area (below fixed header) */}
      <Flex pt="70px" position="relative">
        {/* Sidebar */}
        <Box
          as="nav"
          w={{ base: "270px", lg: "300px" }}
          bg="white"
          h="calc(100vh - 70px)"
          position="fixed"
          top="70px"
          left={0}
          transform={{
            base: showSidebar ? "translateX(0)" : "translateX(-100%)",
            md: "translateX(0)",
          }}
          transition="transform 0.3s ease"
          zIndex={20}
          borderRightWidth="1px"
          borderRightColor="gray.200"
          overflowY="auto"
          display={{ base: showSidebar ? "block" : "none", md: "block" }}
        >
          <VStack h="full" spacing={8} py={4} px={4}>
            {/* Navigation Links */}
            <VStack w="full" spacing={2} align="stretch">
              {data.map((item) => (
                <NavLink key={item.label} item={item} />
              ))}
            </VStack>

            {/* User Info and Logout */}
            <VStack mt="auto" w="full" spacing={4} align="stretch">
              <HStack spacing={3}>
                <Box
                  p={2}
                  borderRadius="lg"
                  bg="primary.50"
                  color="primary.800"
                >
                  <Icon as={IconUser} boxSize={5} />
                </Box>
                <Box>
                  <Text fontWeight="500" color="primary.800" fontSize="sm">
                    {userData.user.name}
                  </Text>
                  <Badge colorScheme="primary" fontSize="xs">
                    {affiliationData.affiliateType === "online"
                      ? "Silver Affiliate"
                      : "Gold Affiliate"}
                  </Badge>
                </Box>
              </HStack>

              <Link
                display="flex"
                alignItems="center"
                p={3}
                borderRadius="md"
                color="gray.600"
                _hover={{
                  bg: "primary.50",
                  color: "primary.800",
                }}
                onClick={logout}
                href="/"
              >
                <Icon as={IconLogout} mr={3} />
                <Text>Logout</Text>
              </Link>
            </VStack>
          </VStack>
        </Box>

        {/* Main Content */}
        <Box
          flex={1}
          ml={{ base: 0, md: "270px", lg: "300px" }}
          bg="gray.50"
          minH="calc(100vh - 70px)"
          w={{
            base: "100%",
            md: "calc(100% - 270px)",
            lg: "calc(100% - 300px)",
          }}
          transition="margin-left 0.3s ease"
          paddingTop={{base:5, md:7, lg:10}}
        >
          {children}
        </Box>

        {/* Overlay for mobile */}
        {showSidebar && (
          <Box
            position="fixed"
            top="70px"
            left={0}
            right={0}
            bottom={0}
            bg="blackAlpha.600"
            display={{ base: "block", md: "none" }}
            onClick={onToggle}
            zIndex={15}
          />
        )}
      </Flex>
    </Box>
  );
}
