// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --primary-color: #0b1152;
    --secondary-color: white;
    --tertiary-color: #e93368;
    --quaternary-color:#fbab20;
    --fifth-color : #00ffc5;
    --quaternary-color-shadow:#fff1da;
    --fifth-color-shadow : #cefff4;
    --tertiary-color-shadow: #ffe9ef;
    --sixth-color : #f5f5f5;
    --seventh-color: #F9FAFF;
    --gray-color: #5f5f5f;

}`, "",{"version":3,"sources":["webpack://./src/Styles/colors.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,yBAAyB;IACzB,0BAA0B;IAC1B,uBAAuB;IACvB,iCAAiC;IACjC,8BAA8B;IAC9B,gCAAgC;IAChC,uBAAuB;IACvB,wBAAwB;IACxB,qBAAqB;;AAEzB","sourcesContent":[":root{\n    --primary-color: #0b1152;\n    --secondary-color: white;\n    --tertiary-color: #e93368;\n    --quaternary-color:#fbab20;\n    --fifth-color : #00ffc5;\n    --quaternary-color-shadow:#fff1da;\n    --fifth-color-shadow : #cefff4;\n    --tertiary-color-shadow: #ffe9ef;\n    --sixth-color : #f5f5f5;\n    --seventh-color: #F9FAFF;\n    --gray-color: #5f5f5f;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
