import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Heading,
  Input,
  Text,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IconSend, IconCheck } from "@tabler/icons-react";
import Closbox from "../../Api/Closbox";
import { useTranslation } from "react-i18next";

export default function PayoutRequestModal({ data, onSuccessfulPayout }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  let unpaidRecords = [];
  let totalAmountDue = 0;

  data.allCommissionRecords.forEach((record) => {
    if (record.status === "confirmed") {
      totalAmountDue += record.commission_amount;
      unpaidRecords.push(record);
    }
  });

  const submitPayoutRequest = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const closboxApi = Closbox.getInstance();

    closboxApi
      .submitPayoutRequest(totalAmountDue, unpaidRecords, data.affiliateID)
      .then((response) => {
        if (response.message === "Ok") {
          onSuccessfulPayout();
          onClose();
          toast({
            title: t("dashboard.billing.modal.success.title"),
            description: t("dashboard.billing.modal.success.description"),
            status: "success",
            duration: 5000,
            isClosable: true,
            icon: <IconCheck />,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: t("dashboard.billing.modal.error.title"),
          description: t("dashboard.billing.modal.error.description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent borderRadius="xl" p={6}>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Heading as="h3" size="lg" color="gray.600">
                {t("dashboard.billing.modal.title")}
              </Heading>

              <Text color="primary.800">
                {t("dashboard.billing.modal.description")}
              </Text>

              <form onSubmit={submitPayoutRequest}>
                <VStack spacing={6}>
                  <FormControl>
                    <FormLabel>
                      {t("dashboard.billing.modal.form.invoiceNumber.label")}
                    </FormLabel>
                    <Input
                      placeholder={t(
                        "dashboard.billing.modal.form.invoiceNumber.placeholder"
                      )}
                      borderRadius="md"
                      size="lg"
                    />
                  </FormControl>

                  <HStack justify="flex-end" width="100%">
                    <Button
                      colorScheme="primary"
                      borderRadius="full"
                      rightIcon={<IconSend size={20} />}
                      isLoading={isLoading}
                      type="submit"
                      size="lg"
                    >
                      {t("dashboard.billing.modal.form.submit")}
                    </Button>
                  </HStack>
                </VStack>
              </form>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <HStack justify="flex-end" mt={4}>
        <Button
          colorScheme="primary"
          borderRadius="full"
          onClick={onOpen}
          size="lg"
        >
          {t("dashboard.billing.modal.newRequest")}
        </Button>
      </HStack>
    </>
  );
}
